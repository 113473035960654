import { ClauseKind } from "Clause";
import * as JsonUtils from "JsonUtils";
import * as NumericalFilterUtils from "NumericalFilterUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import * as TextFilterUtils from "TextFilterUtils";
import * as TimestampFilterUtils from "TimestampFilterUtils";
import * as Verify from "Utils/Verify";
export function fromJson(str) {
    const query = JsonUtils.parse(str);
    if (query.graphTimestampFilter) {
        query.graphTimestampFilter = TimestampFilterUtils.fromJson(JsonUtils.stringify(query.graphTimestampFilter));
    }
    query.clauses = query.clauses.map((clause) => {
        if (clause.columnName === SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time)) {
            clause.filter = TimestampFilterUtils.fromJson(JsonUtils.stringify(clause.filter));
        }
        return clause;
    });
    return query;
}
export function toSqlQuery(query) {
    if (!query) {
        return `SELECT * FROM events;`;
    }
    const clauses = [];
    for (const clause of query.clauses) {
        switch (clause.kind) {
            case ClauseKind.Number:
                clauses.push(NumericalFilterUtils.toSql(clause.columnName, clause.filter));
                break;
            case ClauseKind.String:
                clauses.push(TextFilterUtils.toSql(clause.columnName, clause.filter));
                break;
            case ClauseKind.Timestamp:
                clauses.push(TimestampFilterUtils.toSql(clause.columnName, clause.filter));
                break;
            default:
                Verify.isNever(clause);
        }
    }
    if (query.graphTimestampFilter) {
        clauses.push(TimestampFilterUtils.toSql(SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time), query.graphTimestampFilter));
    }
    return clauses.length > 0
        ? `SELECT * FROM events WHERE (${clauses.map((c) => `(${c})`).join(" AND ")});`
        : `SELECT * FROM events;`;
}
