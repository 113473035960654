import * as ResultUtils from "Utils/ResultUtils";
export function parse(value) {
    const utcDateString = value.endsWith("Z") ? value : value + "Z";
    const date = new Date(utcDateString);
    if (!isNaN(date.getTime())) {
        return ResultUtils.success(date);
    }
    else {
        return ResultUtils.failure("Invalid date");
    }
}
export function formatForDisplay(date) {
    const dateString = date.toISOString().split("T")[0];
    const timeString = date.toISOString().split("T")[1].split(".")[0];
    return `${dateString} ${timeString}`;
}
