export var Granularity;
(function (Granularity) {
    Granularity["SevenDays"] = "SevenDays";
    Granularity["Day"] = "Day";
    Granularity["SixHours"] = "SixHours";
    Granularity["TwoHours"] = "TwoHours";
    Granularity["Hour"] = "Hour";
    Granularity["ThirtyMintues"] = "ThirtyMintues";
    Granularity["TenMinutes"] = "TenMinutes";
    Granularity["Minute"] = "Minute";
    Granularity["TenSeconds"] = "TenSeconds";
    Granularity["FiveSeconds"] = "FiveSeconds";
    Granularity["Second"] = "Second";
})(Granularity || (Granularity = {}));
