import * as React from "react";
import { Checkbox } from "DesignComponents/Checkbox";
export function SettingsPage(props) {
    return (React.createElement("div", { className: "w-full flex flex-col items-center mt-8 justify-center" },
        React.createElement("div", { className: "text-white text-lg mb-6" }, "Dashboard Settings"),
        React.createElement("div", { className: "space-y-4 text-sm" },
            React.createElement(Checkbox, { checked: props.settings.highlightNon200Rows, classNames: {
                    checkbox: "bg-transparent border-white text-white",
                    label: "text-white text-sm",
                }, onCheckedChange: (checkedState) => onSettingsChanged("highlightNon200Rows", !!checkedState), labelElement: "Highlight rows that don't have a 200 response status code" }))));
    function onSettingsChanged(key, value) {
        props.onSettingsChanged({ ...props.settings, [key]: value });
    }
}
