export function clamp(value, min, max) {
    return Math.max(min, Math.min(value, max));
}
export function ensureInteger(value) {
    if (!Number.isInteger(value)) {
        throw new Error(`Expected an integer, got value: ${value}`);
    }
    return value;
}
export function getFraction(value, start, end) {
    return (value - start) / (end - start);
}
export function getInterpolatedValue(start, end, fraction) {
    return start * (1 - fraction) + end * fraction;
}
export function isBetween(value, start, end) {
    return value >= start && value <= end;
}
export function roundDownToGranularity(value, zeroPoint, granularity) {
    return zeroPoint + Math.floor((value - zeroPoint) / granularity) * granularity;
}
export function roundToGranularity(value, zeroPoint, granularity) {
    return roundDownToGranularity(value + granularity / 2, zeroPoint, granularity);
}
export function roundUpToGranularity(value, zeroPoint, granularity) {
    return zeroPoint + Math.ceil((value - zeroPoint) / granularity) * granularity;
}
