import { TimestampFilterKind } from "TimestampFilter";
import * as Verify from "Utils/Verify";
export function fromJson(encoded) {
    const filter = JSON.parse(encoded);
    switch (filter.kind) {
        case TimestampFilterKind.After:
            filter.value = new Date(filter.value);
            break;
        case TimestampFilterKind.Before:
            filter.value = new Date(filter.value);
            break;
        case TimestampFilterKind.Between:
            filter.lowerBound = new Date(filter.lowerBound);
            filter.upperBound = new Date(filter.upperBound);
    }
    return filter;
}
export function toSql(columnName, filter) {
    switch (filter.kind) {
        case TimestampFilterKind.After:
            return `${columnName} > parseDateTime64BestEffort('${filter.value.toISOString()}')`;
        case TimestampFilterKind.AfterOrEquals:
            return `${columnName} >= parseDateTime64BestEffort('${filter.value.toISOString()}')`;
        case TimestampFilterKind.Before:
            return `${columnName} < parseDateTime64BestEffort('${filter.value.toISOString()}')`;
        case TimestampFilterKind.BeforeOrEquals:
            return `${columnName} <= parseDateTime64BestEffort('${filter.value.toISOString()}')`;
        case TimestampFilterKind.Between:
            return `${columnName} >= parseDateTime64BestEffort('${filter.lowerBound.toISOString()}') AND ${columnName} <= parseDateTime64BestEffort('${filter.upperBound.toISOString()}')`;
        default:
            Verify.isNever(filter);
    }
}
