import * as React from "react";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";
import { DeleteConfirmationDialog } from "DeleteConfirmationDialog";
import { InsufficientPermissionsDialog } from "InsufficientPermissionsDialog";
import { NamespaceCreationDialog } from "NamespaceCreationDialog";
export function NamespacesPage(props) {
    const [namespaces, setNamespaces] = React.useState(undefined);
    const [orgId, setOrgId] = React.useState(undefined);
    const dialogManager = CustomHooks.useDialogManager();
    React.useEffect(() => {
        const controller = new AbortController();
        refreshNamespaces(controller.signal);
        return () => {
            controller.abort("Cleaning up effect getNamespaces");
        };
    }, []);
    if (orgId == undefined || namespaces == undefined) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "w-full flex justify-center" },
            React.createElement("div", { className: "w-full max-w-xl px-8 py-12" },
                React.createElement("div", { className: "flex flex-col space-y-8" },
                    React.createElement("div", { className: "flex flex-row justify-end" },
                        React.createElement(Button, { className: "h-8 outline-0 font-medium text-xs bg-sky-900 hover:bg-sky-800 text-zinc-200", label: "Create new namespace", onClick: () => createNamespace(orgId) })),
                    React.createElement("div", { className: "flex flex-col divide-y border border-zinc-800/80" }, namespaces.map((namespace) => renderRow(namespace))))))));
    async function refreshNamespaces(signal) {
        try {
            const listOrgsRequest = {};
            const listOrgsResponse = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, { signal });
            await ApiUtils.assertStatus(listOrgsResponse, 200);
            const { orgs } = await listOrgsResponse.json();
            ArrayUtils.assertLengthAtLeast(orgs, 1);
            setOrgId(orgs[0].orgId);
            // We currently assume that there's only one org and we're always dealing with the first one
            const request = { orgId: orgs[0].orgId };
            const response = await ApiUtils.post("/api/ListNamespaces", request, { signal });
            await ApiUtils.assertStatus(response, 200);
            const { namespaces } = await response.json();
            setNamespaces(namespaces);
        }
        catch {
            if (signal?.aborted) {
                // Do nothing, the API call was canceled
            }
        }
    }
    function renderRow(namespace) {
        return (React.createElement("div", { key: namespace.namespaceId, className: StyleUtils.mergeClassNames("flex flex-row space-x-4 h-12 px-4 border-zinc-800/80 justify-between group/item items-center text-white", {
                "bg-zinc-700/40": namespace.namespaceId === props.namespace?.namespaceId,
            }) },
            React.createElement("div", { className: "flex flex-col text-xs overflow-x-hidden" },
                React.createElement("span", { className: "overflow-x-hidden text-nowrap text-ellipsis" }, namespace.name),
                React.createElement("span", { className: "text-zinc-500" },
                    "Created: ",
                    new Date(namespace.createTime).toDateString())),
            namespace.namespaceId === props.namespace?.namespaceId ? (React.createElement("span", { className: "w-16 text-xs flex flex-row justify-center" }, "(Current)")) : (React.createElement(Button, { className: StyleUtils.mergeClassNames("text-xs invisible text-red-600 bg-zinc-800 h-8 w-16 outline-0 rounded", "hover:bg-red-600 hover:text-white", "group-hover/item:visible"), label: "Delete", onClick: () => deleteNamespace(namespace.namespaceId) }))));
    }
    async function createNamespace(orgId) {
        const name = await dialogManager.show(React.createElement(NamespaceCreationDialog, { closeModal: (name) => dialogManager.hide(name) }));
        if (name === undefined) {
            return;
        }
        const request = { orgId, name };
        const response = await ApiUtils.post("/api/CreateNamespace", request, /* options */ {});
        if (response.status === 403) {
            await dialogManager.show(React.createElement(InsufficientPermissionsDialog, { message: "You do not have permissions to create a namespace.", closeModal: () => dialogManager.hide(undefined) }));
        }
        await ApiUtils.assertStatus(response, 200);
        await refreshNamespaces();
    }
    async function deleteNamespace(namespaceId) {
        const wasCommitted = await dialogManager.show(React.createElement(DeleteConfirmationDialog, { message: "Are you sure you want to delete this namespace? This action cannot be undone.", closeModal: (wasCommitted) => dialogManager.hide(wasCommitted) }));
        if (!wasCommitted) {
            return;
        }
        const request = { namespaceId };
        const response = await ApiUtils.post("/api/DeleteNamespace", request, /* options */ {});
        if (response.status === 403) {
            await dialogManager.show(React.createElement(InsufficientPermissionsDialog, { message: "You do not have permissions to delete this namespace.", closeModal: () => dialogManager.hide(/* wasCommitted */ false) }));
        }
        await ApiUtils.assertStatus(response, 200);
        await refreshNamespaces();
    }
}
