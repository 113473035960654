import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Granularity } from "Granularity";
import * as DateUtils from "DateUtils";
import * as MathUtils from "Utils/MathUtils";
import * as StyleUtils from "Utils/StyleUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import * as GranularityUtils from "GranularityUtils";
import * as Verify from "Utils/Verify";
const BRUSH_HANDLE_THICKNESS_PIXELS = 3;
const PAN_HANDLE_HEIGHT_PERCENTAGE = 20;
const TALLEST_BAR_HEIGHT_PERCENTAGE = 60;
const NUMBER_OF_BARS_ON_SCREEN = 120;
const NUMBER_OF_BARS_TO_MOVE_GRAPH_BY = 60;
const EPSILON = 1e-3;
const BRUSH_HANDLE_COLOR = "bg-blue-500";
const PAN_HANDLE_COLOR = "bg-yellow-900/40";
const NEW_DRAGGED_REGION_COLOR = "bg-red-400/50";
const REQUEST_BAR_COLOR = "bg-green-800";
const NON_FOCUSED_REGION_COLOR = "bg-zinc-800/50";
const TIME_RANGE_BUTTONS_COLOR = "bg-yellow-400";
export function RequestGraph(props) {
    // Using an immutable "now" that doesn't change on every render, but should
    // update when the component remounts.
    const nowTimestamp = React.useRef(Date.now()).current;
    const [globalLeftTimestamp, setGlobalLeftTimestamp] = React.useState(MathUtils.roundUpToGranularity(nowTimestamp, 0, GranularityUtils.getIntervalDuration(Granularity.TwoHours)) -
        NUMBER_OF_BARS_ON_SCREEN * GranularityUtils.getIntervalDuration(Granularity.TwoHours));
    const [globalRightTimestamp, setGlobalRightTimestamp] = React.useState(MathUtils.roundUpToGranularity(nowTimestamp, 0, GranularityUtils.getIntervalDuration(Granularity.TwoHours)));
    const [focusedLeftTimestamp, setFocusedLeftTimestamp] = React.useState(globalLeftTimestamp);
    const [focusedRightTimestamp, setFocusedRightTimestamp] = React.useState(globalRightTimestamp);
    const [newIntervalStartTimestamp, setNewIntervalStartTimestamp] = React.useState(undefined);
    const [newIntervalEndTimestamp, setNewIntervalEndTimestamp] = React.useState(undefined);
    const dataGranularity = GranularityUtils.getBestFitFromIntervalDuration((globalRightTimestamp - globalLeftTimestamp) / NUMBER_OF_BARS_ON_SCREEN);
    const dataGranularityInterval = GranularityUtils.getIntervalDuration(dataGranularity);
    const [dragState, setDragState] = React.useState({ isDragging: false });
    const [graphRootBoundingRect, setGraphRootBoundingRect] = React.useState(undefined);
    const [quantizedMousePositionTimestamp, setQuantizedMousePositionTimestamp] = React.useState(undefined);
    const [shouldDisplayMousePositionTimestampLine, setShouldDisplayMousePositionTimestampLine] = React.useState(false);
    const [shouldDisplayMousePositionTimestamp, setShouldDisplayMousePositionTimestamp] = React.useState(false);
    React.useEffect(() => {
        function onDocumentMouseMove(event) {
            if (!graphRootBoundingRect) {
                return;
            }
            const timestampFraction = MathUtils.getFraction(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right);
            const timestamp = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, timestampFraction);
            const quantizedTimestamp = MathUtils.roundToGranularity(timestamp, globalLeftTimestamp, dataGranularityInterval);
            setQuantizedMousePositionTimestamp(quantizedTimestamp);
            setShouldDisplayMousePositionTimestampLine(MathUtils.isBetween(event.clientY, graphRootBoundingRect.top, MathUtils.getInterpolatedValue(graphRootBoundingRect.top, graphRootBoundingRect.bottom, 1 - PAN_HANDLE_HEIGHT_PERCENTAGE / 100)) &&
                MathUtils.isBetween(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right) &&
                !dragState.isDragging);
            if (dragState.isDragging) {
                switch (dragState.kind) {
                    case DragStateKind.LeftHandle: {
                        const maxPossibleLeftTimestamp = MathUtils.roundDownToGranularity(focusedRightTimestamp - EPSILON, globalLeftTimestamp, dataGranularityInterval);
                        setFocusedLeftTimestamp(MathUtils.clamp(quantizedTimestamp, globalLeftTimestamp, maxPossibleLeftTimestamp));
                        return;
                    }
                    case DragStateKind.RightHandle: {
                        const minPossibleRightTimestamp = MathUtils.roundUpToGranularity(focusedLeftTimestamp + EPSILON, globalLeftTimestamp, dataGranularityInterval);
                        setFocusedRightTimestamp(MathUtils.clamp(quantizedTimestamp, minPossibleRightTimestamp, globalRightTimestamp));
                        return;
                    }
                    case DragStateKind.Pan: {
                        const windowDuration = focusedRightTimestamp - focusedLeftTimestamp;
                        if (event.clientX - dragState.leftOffsetFromPanHandle <= graphRootBoundingRect.left) {
                            // Clamp left
                            setFocusedLeftTimestamp(globalLeftTimestamp);
                            setFocusedRightTimestamp(globalLeftTimestamp + windowDuration);
                        }
                        else if (event.clientX + dragState.rightOffsetFromPanHandle >= graphRootBoundingRect.right) {
                            // Clamp right
                            setFocusedLeftTimestamp(globalRightTimestamp - windowDuration);
                            setFocusedRightTimestamp(globalRightTimestamp);
                        }
                        else {
                            // Clamp neither
                            const windowStartTimestampFraction = MathUtils.getFraction(event.clientX - dragState.leftOffsetFromPanHandle, graphRootBoundingRect.left, graphRootBoundingRect.right);
                            const windowStartTimestamp = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, windowStartTimestampFraction);
                            const quantizedWindowStartTimestamp = MathUtils.roundToGranularity(windowStartTimestamp, globalLeftTimestamp, dataGranularityInterval);
                            setFocusedLeftTimestamp(quantizedWindowStartTimestamp);
                            setFocusedRightTimestamp(quantizedWindowStartTimestamp + windowDuration);
                        }
                        return;
                    }
                    case DragStateKind.NewInterval: {
                        const timestampFraction = MathUtils.getFraction(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right);
                        const timestamp = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, timestampFraction);
                        const quantizedTimestamp = MathUtils.roundToGranularity(timestamp, globalLeftTimestamp, dataGranularityInterval);
                        setNewIntervalEndTimestamp(MathUtils.clamp(quantizedTimestamp, globalLeftTimestamp, globalRightTimestamp));
                        return;
                    }
                    default:
                        Verify.isNever(dragState);
                }
            }
        }
        function onDocumentMouseUp() {
            if (dragState.isDragging && graphRootBoundingRect) {
                if (dragState.kind === DragStateKind.NewInterval && newIntervalStartTimestamp !== undefined && newIntervalEndTimestamp !== undefined) {
                    const newIntervalLeftTimestamp = Math.min(newIntervalStartTimestamp, newIntervalEndTimestamp);
                    const newIntervalRightTimestamp = Math.max(newIntervalStartTimestamp, newIntervalEndTimestamp);
                    // Only commit the new timestamp interval if it's at least one bar wide
                    if (newIntervalRightTimestamp - newIntervalLeftTimestamp >= dataGranularityInterval) {
                        setFocusedLeftTimestamp(newIntervalLeftTimestamp);
                        setFocusedRightTimestamp(newIntervalRightTimestamp);
                        setNewIntervalStartTimestamp(undefined);
                        setNewIntervalEndTimestamp(undefined);
                        props.onTimeRangeChanged(new Date(newIntervalLeftTimestamp), new Date(newIntervalRightTimestamp));
                    }
                }
                else {
                    props.onTimeRangeChanged(new Date(focusedLeftTimestamp), new Date(focusedRightTimestamp));
                }
                setDragState({ isDragging: false });
            }
        }
        function onDocumentKeyDownOrUp(event) {
            setShouldDisplayMousePositionTimestamp(event.ctrlKey || event.metaKey);
        }
        document.addEventListener("mouseup", onDocumentMouseUp);
        document.addEventListener("mousemove", onDocumentMouseMove);
        document.addEventListener("keydown", onDocumentKeyDownOrUp);
        document.addEventListener("keyup", onDocumentKeyDownOrUp);
        return () => {
            document.removeEventListener("mouseup", onDocumentMouseUp);
            document.removeEventListener("mousemove", onDocumentMouseMove);
            document.removeEventListener("keydown", onDocumentKeyDownOrUp);
            document.removeEventListener("keyup", onDocumentKeyDownOrUp);
        };
    }, [
        graphRootBoundingRect,
        dataGranularityInterval,
        dragState,
        focusedLeftTimestamp,
        focusedRightTimestamp,
        globalLeftTimestamp,
        globalRightTimestamp,
        newIntervalEndTimestamp,
        newIntervalStartTimestamp,
        props,
    ]);
    if (!props.gridRows) {
        return null;
    }
    const aggregateByTimestamp = new Map();
    let maxCount = 0;
    if (graphRootBoundingRect) {
        props.gridRows.forEach((event) => {
            const dateString = event[SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time)];
            const date = new Date(dateString.endsWith("Z") ? dateString : dateString + "Z"); // Ensures UTC
            const roundedTimestamp = MathUtils.roundDownToGranularity(date.valueOf(), 0, dataGranularityInterval);
            const currentAggregate = aggregateByTimestamp.get(roundedTimestamp) ?? { count: 0 };
            aggregateByTimestamp.set(roundedTimestamp, { ...currentAggregate, count: currentAggregate.count + 1 });
        });
        aggregateByTimestamp.forEach((aggregate) => {
            maxCount = Math.max(maxCount, aggregate.count);
        });
    }
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: "w-full h-24 relative flex flex-row space-x-1" },
            React.createElement("div", { className: "w-10 h-full flex flex-row items-center justify-center z-10" },
                React.createElement("button", { className: "ml-2 rounded-full hover:bg-zinc-700/80", onClick: () => translateTimeRange(/* backward */ true), title: "Backward" },
                    React.createElement(ChevronLeftIcon, { className: "pr-[2px] text-zinc-400", strokeWidth: 3, size: 20 }))),
            React.createElement("div", { className: "bg-white relative grow", ref: (ref) => {
                    if (ref && !graphRootBoundingRect) {
                        const rect = ref.getBoundingClientRect();
                        setGraphRootBoundingRect(rect);
                    }
                } },
                renderContent(),
                renderControls()),
            React.createElement("div", { className: "w-10 h-full flex flex-row items-center justify-center z-10" },
                React.createElement("button", { className: "mr-2 rounded-full hover:bg-zinc-700/80", onClick: () => translateTimeRange(/* backward */ false), title: "Forward" },
                    React.createElement(ChevronRightIcon, { className: "pl-[2px] text-zinc-400", strokeWidth: 3, size: 20 })))),
        React.createElement("div", { className: "w-full h-5 relative" }, renderLeftAndRightGlobalTimestamps())));
    function getTimestampDisplayGranularity() {
        switch (dataGranularity) {
            case Granularity.SevenDays:
                return Granularity.SevenDays;
            case Granularity.Day:
                return Granularity.SevenDays;
            case Granularity.SixHours:
                return Granularity.Day;
            case Granularity.TwoHours:
                return Granularity.Day;
            case Granularity.Hour:
                return Granularity.SixHours;
            case Granularity.ThirtyMintues:
                return Granularity.TwoHours;
            case Granularity.TenMinutes:
                return Granularity.Hour;
            case Granularity.Minute:
                return Granularity.TenMinutes;
            case Granularity.TenSeconds:
                return Granularity.Minute;
            case Granularity.FiveSeconds:
                return Granularity.Minute;
            case Granularity.Second:
                return Granularity.TenSeconds;
            default:
                Verify.isNever(dataGranularity);
        }
    }
    function getTimestampDisplayString(timestamp, granularity) {
        switch (granularity) {
            case Granularity.SevenDays:
            case Granularity.Day:
                return new Date(timestamp).toISOString().split("T")[0].slice(5);
            case Granularity.SixHours:
            case Granularity.TwoHours:
            case Granularity.Hour: {
                const date = new Date(timestamp);
                return `${date.getUTCHours().toString().padStart(2, "0")}:00`;
            }
            case Granularity.ThirtyMintues:
            case Granularity.TenMinutes:
            case Granularity.Minute: {
                const date = new Date(timestamp);
                return `${date.getUTCHours().toString().padStart(2, "0")}:${date.getUTCMinutes().toString().padStart(2, "0")}`;
            }
            case Granularity.TenSeconds:
            case Granularity.FiveSeconds:
            case Granularity.Second: {
                const date = new Date(timestamp);
                return `${date.getUTCHours().toString().padStart(2, "0")}:${date.getUTCMinutes().toString().padStart(2, "0")}:${date.getUTCSeconds().toString().padStart(2, "0")}`;
            }
            default:
                Verify.isNever(granularity);
        }
    }
    function translateTimeRange(backward) {
        const delta = NUMBER_OF_BARS_TO_MOVE_GRAPH_BY * dataGranularityInterval * (backward ? -1 : 1);
        const newGlobalLeftTimestamp = globalLeftTimestamp + delta;
        const newGlobalRightTimestamp = globalRightTimestamp + delta;
        setGlobalLeftTimestamp(newGlobalLeftTimestamp);
        setGlobalRightTimestamp(newGlobalRightTimestamp);
        // Reset the focused window to the entire window if it doesn't fit in the new range
        if (focusedLeftTimestamp < newGlobalLeftTimestamp || focusedRightTimestamp > newGlobalRightTimestamp) {
            setFocusedLeftTimestamp(newGlobalLeftTimestamp);
            setFocusedRightTimestamp(newGlobalRightTimestamp);
        }
        props.onTimeRangeChanged(new Date(newGlobalLeftTimestamp), new Date(newGlobalRightTimestamp));
    }
    function renderControls() {
        return (React.createElement("div", { className: "flex flex-row space-x-1 text-xs font-mono select-none", style: { position: "absolute", top: "5%", right: "5%" } },
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => updateDataGranularity(Granularity.Minute) }, "1m"),
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => updateDataGranularity(Granularity.TenMinutes) }, "10m"),
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => updateDataGranularity(Granularity.ThirtyMintues) }, "30m"),
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => updateDataGranularity(Granularity.Hour) }, "1h"),
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => updateDataGranularity(Granularity.SixHours) }, "6h"),
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => updateDataGranularity(Granularity.Day) }, "1d"),
            React.createElement("button", { className: `${TIME_RANGE_BUTTONS_COLOR} px-1 hover:brightness-110`, onClick: () => zoomToRange() }, "Zoom to range")));
    }
    function renderContent() {
        return (React.createElement(React.Fragment, null,
            renderDataBars(),
            renderMousePositionTimestampLine(),
            renderMousePositionTimestamp(),
            renderXAxisTimestamps(),
            renderXAxis(),
            renderNewIntervalDragRectangle(),
            renderNonFocusedRegionOverlay(),
            renderNewIntervalDragTargetOverlay(),
            renderPanHandleAndBrushes()));
    }
    function renderDataBars() {
        return (React.createElement("div", { id: "dataBars", style: { position: "absolute", inset: 0 } }, [...aggregateByTimestamp].map(([timestamp, aggregate]) => {
            const timestampFraction = MathUtils.getFraction(timestamp, globalLeftTimestamp, globalRightTimestamp);
            if (timestampFraction < 0 || timestampFraction > 1) {
                return null;
            }
            return aggregate.count > 0 ? (React.createElement("div", { className: `${REQUEST_BAR_COLOR} animate-fade-in border border-black box-border`, key: timestamp, style: {
                    position: "absolute",
                    left: `${timestampFraction * 100}%`,
                    height: `${(aggregate.count * TALLEST_BAR_HEIGHT_PERCENTAGE) / maxCount}%`,
                    bottom: `${PAN_HANDLE_HEIGHT_PERCENTAGE}%`,
                    width: `${(1 / NUMBER_OF_BARS_ON_SCREEN) * 100}%`,
                } })) : null;
        })));
    }
    function renderLeftAndRightGlobalTimestamps() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "bg-black", style: { position: "absolute" } }),
            React.createElement("div", { id: "globalLeftTimestamp", className: "text-xs text-white select-none", style: { position: "absolute", bottom: 0, left: 5 } }, DateUtils.formatForDisplay(new Date(globalLeftTimestamp))),
            React.createElement("div", { id: "globalRightTimestamp", className: "text-xs text-white select-none", style: { position: "absolute", bottom: 0, right: 5 } }, DateUtils.formatForDisplay(new Date(globalRightTimestamp)))));
    }
    function renderNewIntervalDragTargetOverlay() {
        if (!graphRootBoundingRect) {
            return null;
        }
        return (React.createElement("div", { id: "dragTargetOverlay", className: "bg-transparent cursor-text", style: { position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }, onMouseDown: (event) => {
                setDragState({ isDragging: true, kind: DragStateKind.NewInterval, clientX: event.clientX });
                const timestampFraction = MathUtils.getFraction(event.clientX, graphRootBoundingRect.left, graphRootBoundingRect.right);
                const timestamp = MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, timestampFraction);
                const quantizedTimestamp = MathUtils.roundToGranularity(timestamp, globalLeftTimestamp, dataGranularityInterval);
                setNewIntervalStartTimestamp(quantizedTimestamp);
            } }));
    }
    function renderNewIntervalDragRectangle() {
        if (newIntervalStartTimestamp === undefined || newIntervalEndTimestamp === undefined) {
            return null;
        }
        const newIntervalStartTimestampFraction = MathUtils.getFraction(newIntervalStartTimestamp, globalLeftTimestamp, globalRightTimestamp);
        const newIntervalEndTimestampFraction = MathUtils.getFraction(newIntervalEndTimestamp, globalLeftTimestamp, globalRightTimestamp);
        return (React.createElement("div", { id: "dragRectangle", className: NEW_DRAGGED_REGION_COLOR, style: {
                position: "absolute",
                left: `${Math.min(newIntervalStartTimestampFraction, newIntervalEndTimestampFraction) * 100}%`,
                right: `${(1 - Math.max(newIntervalStartTimestampFraction, newIntervalEndTimestampFraction)) * 100}%`,
                top: 0,
                bottom: 0,
            } }));
    }
    function renderNonFocusedRegionOverlay() {
        const focusedLeftTimestampFraction = MathUtils.getFraction(focusedLeftTimestamp, globalLeftTimestamp, globalRightTimestamp);
        const focusedRightTimestampFraction = MathUtils.getFraction(focusedRightTimestamp, globalLeftTimestamp, globalRightTimestamp);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { id: "nonFocusedRegionLeft", className: NON_FOCUSED_REGION_COLOR, style: {
                    position: "absolute",
                    left: 0,
                    right: `${(1 - focusedLeftTimestampFraction) * 100}%`,
                    top: 0,
                    bottom: 0,
                } }),
            React.createElement("div", { id: "nonFocusedRegionRight", className: NON_FOCUSED_REGION_COLOR, style: {
                    position: "absolute",
                    left: `${focusedRightTimestampFraction * 100}%`,
                    right: 0,
                    top: 0,
                    bottom: 0,
                } })));
    }
    function renderPanHandleAndBrushes() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { id: "panHandle", className: StyleUtils.mergeClassNames(PAN_HANDLE_COLOR, dragState.isDragging && dragState.kind === DragStateKind.Pan ? "cursor-grabbing" : "cursor-grab"), style: {
                    position: "absolute",
                    left: `${MathUtils.getFraction(focusedLeftTimestamp, globalLeftTimestamp, globalRightTimestamp) * 100}%`,
                    right: `${(1 - MathUtils.getFraction(focusedRightTimestamp, globalLeftTimestamp, globalRightTimestamp)) * 100}%`,
                    bottom: 0,
                    height: `${PAN_HANDLE_HEIGHT_PERCENTAGE}%`,
                }, onMouseDown: (event) => {
                    setDragState({
                        isDragging: true,
                        kind: DragStateKind.Pan,
                        leftOffsetFromPanHandle: event.nativeEvent.offsetX,
                        rightOffsetFromPanHandle: event.currentTarget.getBoundingClientRect().width - event.nativeEvent.offsetX,
                        clientX: event.clientX,
                    });
                } }),
            React.createElement("div", { id: "leftBrushHandle", className: `${BRUSH_HANDLE_COLOR} cursor-ew-resize`, style: {
                    position: "absolute",
                    left: `${MathUtils.getFraction(focusedLeftTimestamp, globalLeftTimestamp, globalRightTimestamp) * 100}%`,
                    width: BRUSH_HANDLE_THICKNESS_PIXELS,
                    top: 0,
                    bottom: 0,
                }, onMouseDown: (event) => {
                    setDragState({ isDragging: true, kind: DragStateKind.LeftHandle, clientX: event.clientX });
                } }),
            React.createElement("div", { id: "rightBrushHandle", className: `${BRUSH_HANDLE_COLOR} cursor-ew-resize`, style: {
                    position: "absolute",
                    right: `${(1 - MathUtils.getFraction(focusedRightTimestamp, globalLeftTimestamp, globalRightTimestamp)) * 100}%`,
                    width: BRUSH_HANDLE_THICKNESS_PIXELS,
                    top: 0,
                    bottom: 0,
                }, onMouseDown: (event) => {
                    setDragState({ isDragging: true, kind: DragStateKind.RightHandle, clientX: event.clientX });
                } })));
    }
    function renderMousePositionTimestamp() {
        if (quantizedMousePositionTimestamp === undefined) {
            return null;
        }
        const timestampFraction = MathUtils.getFraction(quantizedMousePositionTimestamp, globalLeftTimestamp, globalRightTimestamp);
        return shouldDisplayMousePositionTimestamp ? (React.createElement("div", { id: "mousePositionTimestamp", className: "text-xs bg-zinc-800 text-white text-nowrap", style: { position: "absolute", left: `${timestampFraction * 100}%`, bottom: "-40%", transform: "translateX(-50%)" } }, DateUtils.formatForDisplay(new Date(quantizedMousePositionTimestamp)))) : null;
    }
    function renderMousePositionTimestampLine() {
        if (quantizedMousePositionTimestamp === undefined || !shouldDisplayMousePositionTimestampLine) {
            return null;
        }
        const timestampFraction = MathUtils.getFraction(quantizedMousePositionTimestamp, globalLeftTimestamp, globalRightTimestamp);
        return (React.createElement("svg", { id: "mousePositionTimestampLine", style: { position: "absolute" }, width: "100%", height: "100%", color: "transparent" },
            React.createElement("line", { x1: `${timestampFraction * 100}%`, y1: "0", x2: `${timestampFraction * 100}%`, y2: "100%", stroke: "red" })));
    }
    function renderXAxisTimestamps() {
        const timestampDisplayGranularity = getTimestampDisplayGranularity();
        const timestampGranularityInterval = GranularityUtils.getIntervalDuration(timestampDisplayGranularity);
        let currentTimestamp = MathUtils.roundDownToGranularity(MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, 1.01), 0, timestampGranularityInterval);
        const displayedTimestamps = [];
        while (currentTimestamp >= MathUtils.roundUpToGranularity(MathUtils.getInterpolatedValue(globalLeftTimestamp, globalRightTimestamp, -0.01), 0, timestampGranularityInterval)) {
            displayedTimestamps.push(currentTimestamp);
            currentTimestamp -= GranularityUtils.getIntervalDuration(timestampDisplayGranularity);
        }
        return (React.createElement("div", { id: "xAxisTimestamps", style: { position: "absolute", inset: 0 } }, displayedTimestamps.map((timestamp) => {
            const timestampFraction = MathUtils.getFraction(timestamp, globalLeftTimestamp, globalRightTimestamp);
            return (React.createElement("span", { className: "text-nowrap animate-fade-in text-xs flex flex-col items-center select-none", key: timestamp, style: {
                    position: "absolute",
                    left: `${timestampFraction * 100}%`,
                    transform: "translateX(-50%)",
                    bottom: 0,
                } },
                React.createElement("div", { className: "bg-black h-1 w-px" }),
                React.createElement("span", null, getTimestampDisplayString(timestamp, timestampDisplayGranularity))));
        })));
    }
    function renderXAxis() {
        return (React.createElement("svg", { id: "xAxis", style: { position: "absolute" }, width: "100%", height: "100%" },
            React.createElement("line", { x1: "0", y1: `${100 - PAN_HANDLE_HEIGHT_PERCENTAGE}%`, x2: "100%", y2: `${100 - PAN_HANDLE_HEIGHT_PERCENTAGE}%`, stroke: "black" })));
    }
    function updateDataGranularity(granularity) {
        const nowTimestamp = Date.now();
        const newGlobalRightTimestamp = MathUtils.roundUpToGranularity(nowTimestamp, 0, GranularityUtils.getIntervalDuration(granularity));
        const newGlobalLeftTimestamp = newGlobalRightTimestamp - GranularityUtils.getIntervalDuration(granularity) * NUMBER_OF_BARS_ON_SCREEN;
        setGlobalLeftTimestamp(newGlobalLeftTimestamp);
        setGlobalRightTimestamp(newGlobalRightTimestamp);
        setFocusedLeftTimestamp(newGlobalLeftTimestamp);
        setFocusedRightTimestamp(newGlobalRightTimestamp);
        props.onTimeRangeChanged(new Date(newGlobalLeftTimestamp), new Date(newGlobalRightTimestamp));
    }
    function zoomToRange() {
        const newDataGranularity = GranularityUtils.getBestFitFromIntervalDuration((focusedRightTimestamp - focusedLeftTimestamp) / NUMBER_OF_BARS_ON_SCREEN);
        const newDataGranularityInterval = GranularityUtils.getIntervalDuration(newDataGranularity);
        const numberOfBarsFocusedInNewGranularity = MathUtils.ensureInteger((focusedRightTimestamp - focusedLeftTimestamp) / newDataGranularityInterval);
        const numberOfUnfocusedBarsToTheRight = Math.floor((NUMBER_OF_BARS_ON_SCREEN - numberOfBarsFocusedInNewGranularity) / 2);
        const newRightGlobalTimestamp = focusedRightTimestamp + numberOfUnfocusedBarsToTheRight * newDataGranularityInterval;
        const newLeftGlobalTimestamp = newRightGlobalTimestamp - NUMBER_OF_BARS_ON_SCREEN * newDataGranularityInterval;
        setGlobalLeftTimestamp(newLeftGlobalTimestamp);
        setGlobalRightTimestamp(newRightGlobalTimestamp);
    }
}
var DragStateKind;
(function (DragStateKind) {
    DragStateKind["LeftHandle"] = "LeftHandle";
    DragStateKind["RightHandle"] = "RightHandle";
    DragStateKind["Pan"] = "Pan";
    DragStateKind["NewInterval"] = "NewInterval";
})(DragStateKind || (DragStateKind = {}));
