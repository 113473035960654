import { CircleXIcon } from "lucide-react";
import { NumericalFilterKind } from "NumericalFilter";
import * as React from "react";
import { ClauseKind } from "Clause";
import { TextFilterKind } from "TextFilter";
import { TimestampFilterKind } from "TimestampFilter";
import * as BigIntUtils from "BigIntUtils";
import * as DateUtils from "DateUtils";
import * as MapUtils from "Utils/MapUtils";
import * as ResultUtils from "Utils/ResultUtils";
import * as StrictUtils from "Utils/StrictUtils";
import * as StyleUtils from "Utils/StyleUtils";
import * as Verify from "Utils/Verify";
export const ClauseEditor = React.forwardRef((props, ref) => {
    const [input, setInput] = React.useState("");
    const [clauseId, setClauseId] = React.useState(undefined);
    const inputRef = React.useRef(null);
    const [suggestions, setSuggestions] = React.useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState(undefined);
    const [errorMessage, setErrorMessage] = React.useState(undefined);
    // Make this an imperative action even though it can technically be expressed as a prop,
    // since it makes sense for the clause editor to own its input value even if it can
    // occasionally be modified from the outside.
    React.useImperativeHandle(ref, () => ({
        setClauseBeingEdited: (clause) => {
            setInput(clause.clauseText);
            unsetSuggestions();
            setClauseId(clause.clauseId);
            inputRef.current?.focus();
        },
    }), []);
    const knownColumnTypesByName = new Map(props.gridData?.meta.map((meta) => [meta.name, getKnownColumnType(meta.type)]) ?? []);
    return (React.createElement("div", { className: StyleUtils.mergeClassNames("relative font-mono text-white text-xs", props.className) },
        React.createElement("div", { className: "absolute w-full h-full p-1 bg-zinc-900 text-white/30 pointer-events-none" },
            React.createElement("span", { className: "flex flex-row items-center h-full" },
                React.createElement("span", { className: "whitespace-pre-wrap" }, input),
                React.createElement("span", { className: "ml-2" }, errorMessage !== undefined ? renderError(errorMessage) : activeSuggestionIndex !== undefined ? renderSuggestion(suggestions[activeSuggestionIndex]) : null))),
        React.createElement("input", { className: "absolute w-full h-full p-1 bg-transparent focus-visible:outline-0 placeholder:text-white/40 placeholder:font-sans", onChange: onInputChange, onKeyDown: onKeyDown, placeholder: "Enter a clause to filter by, eg: http_req_method = 'GET'", ref: inputRef, spellCheck: false, value: input })));
    function renderError(errorMessage) {
        return (React.createElement("span", { className: "flex flex-row space-x-2 items-center text-red-600" },
            React.createElement(CircleXIcon, { size: 16 }),
            React.createElement("span", null, errorMessage)));
    }
    function renderSuggestion(suggestion) {
        const parsedInput = getParsedInput(input);
        const textToBeReplaced = getTextToBeReplaced(parsedInput);
        const parts = [];
        let currentIndex = 0;
        while (currentIndex < suggestion.displayString.length) {
            const matchIndex = suggestion.displayString.toLowerCase().indexOf(textToBeReplaced.toLowerCase(), currentIndex);
            if (matchIndex === -1) {
                break;
            }
            parts.push(React.createElement("span", null, suggestion.displayString.slice(currentIndex, matchIndex)));
            parts.push(React.createElement("span", { className: "text-white/60 underline underline-offset-8 decoration-white/60" }, suggestion.displayString.slice(matchIndex, matchIndex + textToBeReplaced.length)));
            currentIndex = matchIndex + textToBeReplaced.length;
        }
        parts.push(React.createElement("span", null, suggestion.displayString.slice(currentIndex)));
        return (React.createElement(React.Fragment, null,
            ...parts,
            suggestion.suffixText ? React.createElement("span", { className: "text-zinc-700" }, suggestion.suffixText) : null));
    }
    function onInputChange(event) {
        const input = event.target.value;
        setInput(input);
        setErrorMessage(undefined);
        // We only support "terminal" auto complete suggestions, nothing in the middle for now
        if (event.target.selectionStart !== event.target.selectionEnd || event.target.selectionStart !== input.length) {
            return;
        }
        const parsedInput = getParsedInput(input);
        const suggestions = getSuggestions(parsedInput);
        setSuggestions(suggestions);
        setActiveSuggestionIndex(suggestions.length > 0 ? 0 : undefined);
    }
    function onKeyDown(event) {
        if (event.key === "Tab") {
            onTabKeyDown(event);
        }
        else if (event.key === "ArrowDown") {
            onDownArrowKeyDown(event);
        }
        else if (event.key === "ArrowUp") {
            onUpArrowKeyDown(event);
        }
        else if (event.key === "Enter") {
            onEnterKeyDown(event);
        }
    }
    function onEnterKeyDown(event) {
        event.preventDefault();
        unsetSuggestions();
        // We don't show the user an error if they try and commit an empty clause,
        // since it would display over the input field placeholder and it's not
        // worth adding logic to deal with that.
        if (input.length === 0) {
            return;
        }
        const parsedInput = getParsedInput(input);
        if (!parsedInput.columnNameRange) {
            setErrorMessage(`Invalid clause`);
            return;
        }
        const columnName = input.substring(parsedInput.columnNameRange[0], parsedInput.columnNameRange[1]);
        if (!parsedInput.operatorRange) {
            const columnType = knownColumnTypesByName.get(columnName) ?? KnownColumnType.String;
            let operatorsString;
            if (columnType === KnownColumnType.Number || columnType === KnownColumnType.Timestamp) {
                operatorsString = "'=' or '<'";
            }
            else if (columnType === KnownColumnType.String) {
                operatorsString = "'=' or 'contains'";
            }
            else {
                Verify.isNever(columnType);
            }
            setErrorMessage(`Invalid clause: Operator (like ${operatorsString}) expected after '${columnName}'`);
            return;
        }
        const operator = input.substring(parsedInput.operatorRange[0], parsedInput.operatorRange[1]);
        if (!parsedInput.valueRange) {
            const operator = input.substring(parsedInput.operatorRange[0], parsedInput.operatorRange[1]);
            setErrorMessage(`Invalid clause: Value expected after '${operator}'`);
            return;
        }
        if (!knownColumnTypesByName.has(columnName)) {
            setErrorMessage(`Invalid clause: Unknown column name '${columnName}'`);
            return;
        }
        const columnType = MapUtils.getOrThrow(knownColumnTypesByName, columnName);
        const value = input.substring(parsedInput.valueRange[0], parsedInput.valueRange[1]);
        if (columnType === KnownColumnType.String) {
            const filterKind = getTextFilterKind(operator);
            if (!filterKind) {
                setErrorMessage(`Invalid clause: Unknown operator '${operator}'`);
                return;
            }
            if (!value.startsWith("'") || !value.endsWith("'")) {
                setErrorMessage(`Invalid clause: Expected single quoted string, got "${value}" instead`);
                return;
            }
            const clause = {
                columnName,
                kind: ClauseKind.String,
                filter: {
                    kind: filterKind,
                    value,
                },
                clauseId: clauseId ?? crypto.randomUUID(),
                clauseText: input,
            };
            props.onClauseCommitted(clause);
            setInput("");
            setClauseId(undefined);
            return;
        }
        if (columnType === KnownColumnType.Number) {
            const filterKind = getNumericalFilterKind(operator);
            if (!filterKind) {
                setErrorMessage(`Invalid clause: Unknown operator '${operator}'`);
                return;
            }
            const result = BigIntUtils.parse(value);
            if (ResultUtils.isFailure(result)) {
                setErrorMessage(`Invalid clause: Value '${value}' is not a valid number`);
                return;
            }
            const clause = {
                columnName,
                kind: ClauseKind.Number,
                filter: {
                    kind: filterKind,
                    value: result.value,
                },
                clauseId: clauseId ?? crypto.randomUUID(),
                clauseText: input,
            };
            props.onClauseCommitted(clause);
            setInput("");
            setClauseId(undefined);
            return;
        }
        if (columnType === KnownColumnType.Timestamp) {
            const filterKind = getTimestampFilterKind(operator);
            if (!filterKind) {
                setErrorMessage(`Invalid clause: Unknown operator '${operator}'`);
                return;
            }
            const result = DateUtils.parse(value);
            if (ResultUtils.isFailure(result)) {
                setErrorMessage(`Invalid clause: Value '${value}' is not a valid number`);
                return;
            }
            const clause = {
                columnName,
                kind: ClauseKind.Timestamp,
                filter: {
                    kind: filterKind,
                    value: result.value,
                },
                clauseId: clauseId ?? crypto.randomUUID(),
                clauseText: input,
            };
            props.onClauseCommitted(clause);
            setInput("");
            setClauseId(undefined);
            return;
        }
        Verify.isNever(columnType);
    }
    function onTabKeyDown(event) {
        if (activeSuggestionIndex !== undefined) {
            event.preventDefault();
            const suggestion = suggestions[activeSuggestionIndex];
            setInput(input.slice(0, suggestion.startingPosition) + suggestion.replacementString);
            unsetSuggestions();
        }
    }
    function onDownArrowKeyDown(event) {
        if (activeSuggestionIndex !== undefined) {
            event.preventDefault();
            const newActiveSuggestionIndex = (activeSuggestionIndex + 1) % suggestions.length;
            setActiveSuggestionIndex(newActiveSuggestionIndex);
        }
    }
    function onUpArrowKeyDown(event) {
        if (activeSuggestionIndex !== undefined) {
            event.preventDefault();
            const newActiveSuggestionIndex = (activeSuggestionIndex + suggestions.length - 1) % suggestions.length;
            setActiveSuggestionIndex(newActiveSuggestionIndex);
        }
    }
    function getSuggestions(parsedInput) {
        // The user needs to type a bit for us for us to have enough context to give them meaningful suggestions.
        const textToBeReplaced = getTextToBeReplaced(parsedInput);
        if (textToBeReplaced.length < 2) {
            return [];
        }
        if (parsedInput.operatorRange && parsedInput.columnNameRange && parsedInput.rawInput.length > parsedInput.operatorRange[1]) {
            // Suggestions for values (RHS)
            const columnName = parsedInput.rawInput.substring(parsedInput.columnNameRange[0], parsedInput.columnNameRange[1]);
            const columnType = knownColumnTypesByName.get(columnName);
            if (columnType !== KnownColumnType.String) {
                return [];
            }
            const columnValues = [...new Set(props.gridData?.data.map((row) => row[columnName]).filter((value) => value !== undefined))];
            const operatorName = parsedInput.rawInput.substring(parsedInput.operatorRange[0], parsedInput.operatorRange[1]);
            const filterKind = getTextFilterKind(operatorName);
            if (!filterKind || filterKind === TextFilterKind.Like) {
                return [];
            }
            return columnValues
                .filter((value) => value.toLowerCase().includes(textToBeReplaced.toLowerCase()))
                .sort((suggestion1, suggestion2) => getMatchScore(textToBeReplaced, suggestion2) - getMatchScore(textToBeReplaced, suggestion1))
                .map((text, _, suggestions) => ({
                replacementString: `'${text}'`,
                displayString: `'${text}'`,
                startingPosition: StrictUtils.ensureDefined(parsedInput.valueRange)[0],
                suffixText: suggestions.length > 1 ? ` (and ${suggestions.length - 1} more)` : undefined,
            }));
        }
        if (parsedInput.columnNameRange && parsedInput.rawInput.length > parsedInput.columnNameRange[1]) {
            // Suggestions for operators
            const columnName = parsedInput.rawInput.substring(parsedInput.columnNameRange[0], parsedInput.columnNameRange[1]);
            const columnType = knownColumnTypesByName.get(columnName);
            if (columnType !== KnownColumnType.String) {
                return [];
            }
            return knownStringOperatorNames
                .filter((operator) => operator.toLowerCase().includes(textToBeReplaced.toLowerCase()) && operator.toLowerCase() !== textToBeReplaced.toLowerCase())
                .sort((suggestion1, suggestion2) => getMatchScore(textToBeReplaced, suggestion2) - getMatchScore(textToBeReplaced, suggestion1))
                .map((text) => ({
                replacementString: text + " ",
                displayString: text,
                startingPosition: StrictUtils.ensureDefined(parsedInput.operatorRange)[0],
            }));
        }
        if (parsedInput.columnNameRange) {
            // Suggestions for column names (LHS)
            return [...knownColumnTypesByName.keys()]
                .filter((field) => field.toLowerCase().includes(textToBeReplaced.toLowerCase()) && field.toLowerCase() !== textToBeReplaced.toLowerCase())
                .sort((suggestion1, suggestion2) => getMatchScore(textToBeReplaced, suggestion2) - getMatchScore(textToBeReplaced, suggestion1))
                .map((text) => ({
                replacementString: text + " ",
                displayString: text,
                startingPosition: StrictUtils.ensureDefined(parsedInput.columnNameRange)[0],
            }));
        }
        return [];
    }
    function unsetSuggestions() {
        setSuggestions([]);
        setActiveSuggestionIndex(undefined);
    }
});
function getKnownColumnType(clickhouseType) {
    switch (clickhouseType) {
        case "UInt64":
        case "Nullable(UInt64)":
            return KnownColumnType.Number;
        case "String":
        case "Nullable(String)":
        case "LowCardinality(String)":
        case "UUID":
            return KnownColumnType.String;
        case "DateTime64(9)":
            return KnownColumnType.Timestamp;
        default:
            return KnownColumnType.String;
    }
}
function getParsedInput(input) {
    return {
        columnNameRange: [...input.matchAll(/^\s*([^\s]+)/dgi)][0]?.indices?.[1],
        operatorRange: [...input.matchAll(/^\s*[^\s]+\s+([^\s]+)/dgi)][0]?.indices?.[1],
        valueRange: [...input.matchAll(/^\s*[^\s]+\s+[^\s]+\s+([^\s].*)/dgi)][0]?.indices?.[1],
        rawInput: input,
    };
}
function getTextToBeReplaced(parsedInput) {
    const inputLength = parsedInput.rawInput.length;
    let wordStart;
    let wordEnd;
    if (parsedInput.valueRange) {
        [wordStart, wordEnd] = parsedInput.valueRange;
    }
    else if (parsedInput.operatorRange) {
        [wordStart, wordEnd] = parsedInput.operatorRange;
    }
    else if (parsedInput.columnNameRange) {
        [wordStart, wordEnd] = parsedInput.columnNameRange;
    }
    if (wordStart !== undefined && wordEnd !== undefined && wordStart <= inputLength && inputLength <= wordEnd) {
        return parsedInput.rawInput.substring(wordStart, wordEnd);
    }
    else {
        return "";
    }
}
function getMatchScore(input, suggestion) {
    const lowerCaseInput = input.toLowerCase();
    const lowerCaseSuggestion = suggestion.toLowerCase();
    if (lowerCaseInput === lowerCaseSuggestion) {
        return -1;
    }
    if (lowerCaseSuggestion.startsWith(lowerCaseInput)) {
        return 2;
    }
    if (lowerCaseSuggestion.includes(lowerCaseInput)) {
        return 1;
    }
    return 0;
}
function getNumericalFilterKind(operator) {
    switch (operator) {
        case "=":
        case "==":
            return NumericalFilterKind.Equals;
        case "!=":
        case "<>":
            return NumericalFilterKind.NotEquals;
        case ">":
            return NumericalFilterKind.GreaterThan;
        case ">=":
            return NumericalFilterKind.GreaterThanOrEquals;
        case "<":
            return NumericalFilterKind.LesserThan;
        case "<=":
            return NumericalFilterKind.LesserThanOrEquals;
        default:
            return undefined;
    }
}
function getTextFilterKind(operator) {
    switch (operator) {
        case "=":
            return TextFilterKind.Equals;
        case "!=":
        case "<>":
            return TextFilterKind.DoesNotEqual;
        case "LIKE":
        case "like":
            return TextFilterKind.Like;
        default:
            return undefined;
    }
}
function getTimestampFilterKind(operator) {
    switch (operator) {
        case ">":
            return TimestampFilterKind.After;
        case ">=":
            return TimestampFilterKind.AfterOrEquals;
        case "<":
            return TimestampFilterKind.Before;
        case "<=":
            return TimestampFilterKind.BeforeOrEquals;
        default:
            return undefined;
    }
}
const knownStringOperatorNames = ["LIKE", "like", "=", "!=", "<>"];
var KnownColumnType;
(function (KnownColumnType) {
    KnownColumnType["String"] = "String";
    KnownColumnType["Number"] = "Number";
    KnownColumnType["Timestamp"] = "Timestamp";
})(KnownColumnType || (KnownColumnType = {}));
