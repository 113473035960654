// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.179.0
//   protoc               v3.21.12
// source: subtrace/event/event.proto
/* eslint-disable */
import { FileDescriptorProto } from "ts-proto-descriptors";
export const protobufPackage = "subtrace.event";
export var KnownFields;
(function (KnownFields) {
    KnownFields[KnownFields["time"] = 0] = "time";
    KnownFields[KnownFields["event_id"] = 1] = "event_id";
    KnownFields[KnownFields["service"] = 2] = "service";
    KnownFields[KnownFields["hostname"] = 3] = "hostname";
    KnownFields[KnownFields["process_id"] = 4] = "process_id";
    KnownFields[KnownFields["process_executable_name"] = 17] = "process_executable_name";
    KnownFields[KnownFields["process_executable_size"] = 18] = "process_executable_size";
    KnownFields[KnownFields["process_command_line"] = 19] = "process_command_line";
    KnownFields[KnownFields["process_user"] = 20] = "process_user";
    KnownFields[KnownFields["protocol"] = 31] = "protocol";
    KnownFields[KnownFields["tls_server_name"] = 5] = "tls_server_name";
    KnownFields[KnownFields["http_version"] = 6] = "http_version";
    KnownFields[KnownFields["http_is_outgoing"] = 7] = "http_is_outgoing";
    KnownFields[KnownFields["http_client_addr"] = 8] = "http_client_addr";
    KnownFields[KnownFields["http_server_addr"] = 9] = "http_server_addr";
    KnownFields[KnownFields["http_duration"] = 10] = "http_duration";
    KnownFields[KnownFields["http_req_method"] = 11] = "http_req_method";
    KnownFields[KnownFields["http_req_path"] = 12] = "http_req_path";
    KnownFields[KnownFields["http_req_body_size_bytes_wire"] = 14] = "http_req_body_size_bytes_wire";
    KnownFields[KnownFields["http_req_transfer_encoding"] = 29] = "http_req_transfer_encoding";
    KnownFields[KnownFields["http_req_content_type"] = 21] = "http_req_content_type";
    KnownFields[KnownFields["http_req_content_encoding"] = 23] = "http_req_content_encoding";
    KnownFields[KnownFields["http_req_content_length"] = 26] = "http_req_content_length";
    KnownFields[KnownFields["http_req_host"] = 25] = "http_req_host";
    KnownFields[KnownFields["http_req_x_forwarded_for"] = 28] = "http_req_x_forwarded_for";
    KnownFields[KnownFields["http_resp_status_code"] = 13] = "http_resp_status_code";
    KnownFields[KnownFields["http_resp_body_size_bytes_wire"] = 15] = "http_resp_body_size_bytes_wire";
    KnownFields[KnownFields["http_resp_transfer_encoding"] = 30] = "http_resp_transfer_encoding";
    KnownFields[KnownFields["http_resp_content_type"] = 16] = "http_resp_content_type";
    KnownFields[KnownFields["http_resp_content_encoding"] = 22] = "http_resp_content_encoding";
    KnownFields[KnownFields["http_resp_content_length"] = 27] = "http_resp_content_length";
    KnownFields[KnownFields["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(KnownFields || (KnownFields = {}));
export function knownFieldsFromJSON(object) {
    switch (object) {
        case 0:
        case "time":
            return KnownFields.time;
        case 1:
        case "event_id":
            return KnownFields.event_id;
        case 2:
        case "service":
            return KnownFields.service;
        case 3:
        case "hostname":
            return KnownFields.hostname;
        case 4:
        case "process_id":
            return KnownFields.process_id;
        case 17:
        case "process_executable_name":
            return KnownFields.process_executable_name;
        case 18:
        case "process_executable_size":
            return KnownFields.process_executable_size;
        case 19:
        case "process_command_line":
            return KnownFields.process_command_line;
        case 20:
        case "process_user":
            return KnownFields.process_user;
        case 31:
        case "protocol":
            return KnownFields.protocol;
        case 5:
        case "tls_server_name":
            return KnownFields.tls_server_name;
        case 6:
        case "http_version":
            return KnownFields.http_version;
        case 7:
        case "http_is_outgoing":
            return KnownFields.http_is_outgoing;
        case 8:
        case "http_client_addr":
            return KnownFields.http_client_addr;
        case 9:
        case "http_server_addr":
            return KnownFields.http_server_addr;
        case 10:
        case "http_duration":
            return KnownFields.http_duration;
        case 11:
        case "http_req_method":
            return KnownFields.http_req_method;
        case 12:
        case "http_req_path":
            return KnownFields.http_req_path;
        case 14:
        case "http_req_body_size_bytes_wire":
            return KnownFields.http_req_body_size_bytes_wire;
        case 29:
        case "http_req_transfer_encoding":
            return KnownFields.http_req_transfer_encoding;
        case 21:
        case "http_req_content_type":
            return KnownFields.http_req_content_type;
        case 23:
        case "http_req_content_encoding":
            return KnownFields.http_req_content_encoding;
        case 26:
        case "http_req_content_length":
            return KnownFields.http_req_content_length;
        case 25:
        case "http_req_host":
            return KnownFields.http_req_host;
        case 28:
        case "http_req_x_forwarded_for":
            return KnownFields.http_req_x_forwarded_for;
        case 13:
        case "http_resp_status_code":
            return KnownFields.http_resp_status_code;
        case 15:
        case "http_resp_body_size_bytes_wire":
            return KnownFields.http_resp_body_size_bytes_wire;
        case 30:
        case "http_resp_transfer_encoding":
            return KnownFields.http_resp_transfer_encoding;
        case 16:
        case "http_resp_content_type":
            return KnownFields.http_resp_content_type;
        case 22:
        case "http_resp_content_encoding":
            return KnownFields.http_resp_content_encoding;
        case 27:
        case "http_resp_content_length":
            return KnownFields.http_resp_content_length;
        case -1:
        case "UNRECOGNIZED":
        default:
            return KnownFields.UNRECOGNIZED;
    }
}
export function knownFieldsToJSON(object) {
    switch (object) {
        case KnownFields.time:
            return "time";
        case KnownFields.event_id:
            return "event_id";
        case KnownFields.service:
            return "service";
        case KnownFields.hostname:
            return "hostname";
        case KnownFields.process_id:
            return "process_id";
        case KnownFields.process_executable_name:
            return "process_executable_name";
        case KnownFields.process_executable_size:
            return "process_executable_size";
        case KnownFields.process_command_line:
            return "process_command_line";
        case KnownFields.process_user:
            return "process_user";
        case KnownFields.protocol:
            return "protocol";
        case KnownFields.tls_server_name:
            return "tls_server_name";
        case KnownFields.http_version:
            return "http_version";
        case KnownFields.http_is_outgoing:
            return "http_is_outgoing";
        case KnownFields.http_client_addr:
            return "http_client_addr";
        case KnownFields.http_server_addr:
            return "http_server_addr";
        case KnownFields.http_duration:
            return "http_duration";
        case KnownFields.http_req_method:
            return "http_req_method";
        case KnownFields.http_req_path:
            return "http_req_path";
        case KnownFields.http_req_body_size_bytes_wire:
            return "http_req_body_size_bytes_wire";
        case KnownFields.http_req_transfer_encoding:
            return "http_req_transfer_encoding";
        case KnownFields.http_req_content_type:
            return "http_req_content_type";
        case KnownFields.http_req_content_encoding:
            return "http_req_content_encoding";
        case KnownFields.http_req_content_length:
            return "http_req_content_length";
        case KnownFields.http_req_host:
            return "http_req_host";
        case KnownFields.http_req_x_forwarded_for:
            return "http_req_x_forwarded_for";
        case KnownFields.http_resp_status_code:
            return "http_resp_status_code";
        case KnownFields.http_resp_body_size_bytes_wire:
            return "http_resp_body_size_bytes_wire";
        case KnownFields.http_resp_transfer_encoding:
            return "http_resp_transfer_encoding";
        case KnownFields.http_resp_content_type:
            return "http_resp_content_type";
        case KnownFields.http_resp_content_encoding:
            return "http_resp_content_encoding";
        case KnownFields.http_resp_content_length:
            return "http_resp_content_length";
        case KnownFields.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export const protoMetadata = {
    fileDescriptor: FileDescriptorProto.fromPartial({
        "name": "subtrace/event/event.proto",
        "package": "subtrace.event",
        "dependency": [],
        "publicDependency": [],
        "weakDependency": [],
        "messageType": [],
        "enumType": [{
                "name": "KnownFields",
                "value": [
                    { "name": "time", "number": 0, "options": undefined },
                    { "name": "event_id", "number": 1, "options": undefined },
                    { "name": "service", "number": 2, "options": undefined },
                    { "name": "hostname", "number": 3, "options": undefined },
                    { "name": "process_id", "number": 4, "options": undefined },
                    { "name": "process_executable_name", "number": 17, "options": undefined },
                    { "name": "process_executable_size", "number": 18, "options": undefined },
                    { "name": "process_command_line", "number": 19, "options": undefined },
                    { "name": "process_user", "number": 20, "options": undefined },
                    { "name": "protocol", "number": 31, "options": undefined },
                    { "name": "tls_server_name", "number": 5, "options": undefined },
                    { "name": "http_version", "number": 6, "options": undefined },
                    { "name": "http_is_outgoing", "number": 7, "options": undefined },
                    { "name": "http_client_addr", "number": 8, "options": undefined },
                    { "name": "http_server_addr", "number": 9, "options": undefined },
                    { "name": "http_duration", "number": 10, "options": undefined },
                    { "name": "http_req_method", "number": 11, "options": undefined },
                    { "name": "http_req_path", "number": 12, "options": undefined },
                    { "name": "http_req_body_size_bytes_wire", "number": 14, "options": undefined },
                    { "name": "http_req_transfer_encoding", "number": 29, "options": undefined },
                    { "name": "http_req_content_type", "number": 21, "options": undefined },
                    { "name": "http_req_content_encoding", "number": 23, "options": undefined },
                    { "name": "http_req_content_length", "number": 26, "options": undefined },
                    { "name": "http_req_host", "number": 25, "options": undefined },
                    { "name": "http_req_x_forwarded_for", "number": 28, "options": undefined },
                    { "name": "http_resp_status_code", "number": 13, "options": undefined },
                    { "name": "http_resp_body_size_bytes_wire", "number": 15, "options": undefined },
                    { "name": "http_resp_transfer_encoding", "number": 30, "options": undefined },
                    { "name": "http_resp_content_type", "number": 16, "options": undefined },
                    { "name": "http_resp_content_encoding", "number": 22, "options": undefined },
                    { "name": "http_resp_content_length", "number": 27, "options": undefined },
                ],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }],
        "service": [],
        "extension": [],
        "options": {
            "javaPackage": "",
            "javaOuterClassname": "",
            "javaMultipleFiles": false,
            "javaGenerateEqualsAndHash": false,
            "javaStringCheckUtf8": false,
            "optimizeFor": 1,
            "goPackage": "subtrace.dev/event",
            "ccGenericServices": false,
            "javaGenericServices": false,
            "pyGenericServices": false,
            "phpGenericServices": false,
            "deprecated": false,
            "ccEnableArenas": true,
            "objcClassPrefix": "",
            "csharpNamespace": "",
            "swiftPrefix": "",
            "phpClassPrefix": "",
            "phpNamespace": "",
            "phpMetadataNamespace": "",
            "rubyPackage": "",
            "uninterpretedOption": [],
        },
        "sourceCodeInfo": { "location": [] },
        "syntax": "proto3",
    }),
    references: { ".subtrace.event.KnownFields": KnownFields },
    dependencies: [],
};
