import React from "react";
import { TriangleAlertIcon, XIcon } from "lucide-react";
import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";
export function GetApiTokenDialog(props) {
    const [showCopiedTooltip, setShowCopiedTooltip] = React.useState(false);
    return (React.createElement("div", { className: "relative h-1/4 bg-zinc-900 p-10 text-sm rounded-lg shadow-lg" },
        React.createElement(XIcon, { className: StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white"), onClick: () => props.closeModal() }),
        React.createElement("div", { className: "flex flex-col justify-between h-full" },
            React.createElement("div", { className: "text-white" },
                React.createElement("div", null,
                    React.createElement("span", null, "Your api key is: "),
                    React.createElement("span", { className: "font-mono " }, props.apiToken)),
                React.createElement("span", { className: "flex flex-row mt-3 space-x-1" },
                    React.createElement(TriangleAlertIcon, { size: 20, className: "text-yellow-600" }),
                    React.createElement("span", null, " This is the only opportunity to see and record this token."))),
            React.createElement("div", { className: "relative w-fit" },
                React.createElement(Button, { className: "rounded h-8 outline-0 border-none", label: "Copy to clipboard", onClick: () => {
                        navigator.clipboard.writeText(props.apiToken);
                        setShowCopiedTooltip(true);
                    }, onMouseLeave: () => setShowCopiedTooltip(false) }),
                React.createElement("span", { className: StyleUtils.mergeClassNames("invisible absolute bottom-[calc(100%+5px)] left-1/2 -translate-x-1/2 text-xs border rounded-sm border-zinc-700/80 text-white p-1 bg-zinc-700 text-nowrap", { visible: showCopiedTooltip }) },
                    React.createElement("span", { className: "absolute top-full left-1/2 -translate-x-1/2" },
                        React.createElement("svg", { className: "fill-zinc-700", width: "10", height: "5", viewBox: "0 0 30 10", preserveAspectRatio: "none", style: { display: "block" } },
                            React.createElement("polygon", { points: "0,0 30,0 15,10" }))),
                    "Copied")))));
}
