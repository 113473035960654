import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { MoveDownLeftIcon, MoveUpRightIcon, XCircleIcon } from "lucide-react";
import * as CustomHooks from "CustomHooks";
import * as DateUtils from "DateUtils";
import * as DurationUtils from "DurationUtils";
import * as JsonUtils from "JsonUtils";
import * as ResultUtils from "Utils/ResultUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import * as Tunnel from "ApiContracts/subtrace/tunnel/tunnel";
import * as UIQueryUtils from "UIQueryUtils";
import { ClauseEditor } from "ClauseEditor";
import { LazyGrid } from "LazyGrid";
import { PinnedColumnCellRenderer } from "PinnedColumnCellRenderer";
import { RequestDetailsSidePanel } from "RequestDetailsSidePanel";
import { RequestGraph } from "RequestGraph";
import { Spinner } from "DesignComponents/Spinner";
import { TimestampFilterKind } from "TimestampFilter";
export function AppContents(props) {
    const { namespaceId } = props;
    // TODO: Unify queryResult and fetchError into an AsyncValue like interface
    const [queryResult, setQueryResult] = React.useState(undefined);
    const [searchParams, setSearchParams] = ReactRouterDom.useSearchParams();
    const initialFilterSearchParam = searchParams.get("filter");
    const [pinnedRows, setPinnedRows] = React.useState([]);
    const clauseEditorRef = React.useRef(null);
    let initialQuery = undefined;
    try {
        if (initialFilterSearchParam != null) {
            initialQuery = UIQueryUtils.fromJson(atob(initialFilterSearchParam));
        }
    }
    catch {
        // Do nothing, the filter is likely not valid JSON
    }
    const [currentEvaluatingQuery, setCurrentEvaluatingQuery] = React.useState(initialQuery ?? {
        clauses: [],
        graphTimestampFilter: undefined,
    });
    const [currentAppliedQuery, setCurrentAppliedQuery] = React.useState({
        clauses: [],
        graphTimestampFilter: undefined,
    });
    const [sidePanelState, setSidePanelState] = React.useState({ isOpen: false });
    const initializedWebSocket = CustomHooks.useInitializedWebSocket(namespaceId);
    const handleWebsocketMessage = React.useCallback((message) => {
        const result = Tunnel.Result.decode(new Uint8Array(message.data));
        if (result.tunnelError) {
            setCurrentEvaluatingQuery(undefined);
            // TODO(sachin): Graceful error handling, reopen websocket for future queries?
            console.error(result.tunnelError);
            return;
        }
        if (result.clickhouseError) {
            setCurrentEvaluatingQuery(undefined);
            // TODO(sachin): Graceful error handling, reopen websocket for future queries?
            console.error(result.clickhouseError);
            return;
        }
        const { meta, data } = JsonUtils.parse(result.result);
        setQueryResult({
            meta,
            data: data.map((row) => {
                const ret = {};
                for (let i = 0; i < meta.length; i++) {
                    if (row[i] !== null) {
                        ret[meta[i].name] = row[i];
                    }
                }
                return ret;
            }),
        });
        setSearchParams((params) => ({
            ...params,
            filter: btoa(JsonUtils.stringify(currentEvaluatingQuery)),
        }), { replace: true });
        setCurrentAppliedQuery(currentEvaluatingQuery);
        setCurrentEvaluatingQuery(undefined);
    }, [currentEvaluatingQuery, setSearchParams]);
    React.useEffect(() => {
        if (initializedWebSocket && ResultUtils.isSuccess(initializedWebSocket) && currentEvaluatingQuery) {
            const webSocket = initializedWebSocket.value;
            const sqlStatement = UIQueryUtils.toSqlQuery(currentEvaluatingQuery);
            console.log("sql:", sqlStatement);
            const sqlQueryMessage = Tunnel.Select.encode({
                sqlStatement,
                tunnelQueryId: crypto.randomUUID(),
            }).finish();
            webSocket.onmessage = handleWebsocketMessage;
            webSocket.send(sqlQueryMessage);
        }
    }, [currentEvaluatingQuery, handleWebsocketMessage, initializedWebSocket]);
    if (!namespaceId) {
        return null;
    }
    if (initializedWebSocket && ResultUtils.isFailure(initializedWebSocket)) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, "There was an error fetching the requests:"),
            React.createElement("div", null, initializedWebSocket.error)));
    }
    let columnDefinitions = [];
    if (queryResult) {
        columnDefinitions = queryResult.meta.map(({ type, name }) => {
            const isKnown = SubtraceEvent.knownFieldsFromJSON(name) !== SubtraceEvent.KnownFields.UNRECOGNIZED;
            return {
                headerName: getKnownHeaderName(name),
                headerClass: isKnown ? "" : "font-mono",
                cellDataType: getKnownCellDataType(type),
                cellRenderer: getKnownCellRenderer(name),
                valueFormatter: getKnownValueFormatter(name),
                width: 200,
            };
        });
    }
    const nonPinnedRows = queryResult ? queryResult.data.filter((row) => !pinnedRows.map((row) => row.event_id).includes(row.event_id)) : undefined;
    const allRows = [...pinnedRows, ...(nonPinnedRows ?? [])];
    return (React.createElement("div", { className: "flex flex-col w-full h-screen" },
        React.createElement(LazyGrid, { fallback: React.createElement("div", { className: "h-[400px] flex justify-center items-center" },
                React.createElement(Spinner, { className: "scale-[200%]" })), className: "h-[400px]", columnDefinitions: [
                {
                    headerName: "",
                    cellRenderer: PinnedColumnCellRenderer,
                    cellRendererParams: {
                        onChange: (checked, event) => {
                            if (checked) {
                                setPinnedRows([...pinnedRows, event]);
                            }
                            else {
                                setPinnedRows(pinnedRows.filter((e) => e["event_id"] !== event["event_id"]));
                            }
                        },
                    },
                    valueGetter: (params) => {
                        if (params.data) {
                            for (let i = 0; i < pinnedRows.length; i++) {
                                if (pinnedRows[i]["event_id"] == params.data["event_id"]) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    },
                },
                ...columnDefinitions,
            ], headerHeight: 45, loading: currentEvaluatingQuery !== undefined, onRowDoubleClicked: onRowDoubleClicked, pinnedTopRowData: pinnedRows, rowClassRules: {
                "last-pinned-row": (params) => pinnedRows.length > 0 && params.rowIndex === pinnedRows.length - 1,
            }, rowData: nonPinnedRows ?? [], rowHeight: 35 }),
        React.createElement(ClauseEditor, { className: "h-10 my-4", gridData: queryResult ? { ...queryResult, data: allRows.slice(0, 1000) } : undefined, onClauseCommitted: onClauseCommitted, ref: clauseEditorRef }),
        React.createElement("div", { className: "px-2" }, renderClauses()),
        sidePanelState.isOpen ? (React.createElement(RequestDetailsSidePanel, { rowData: sidePanelState.rowData, closeSidePanel: () => setSidePanelState({ isOpen: false }), getKnownHeaderName: getKnownHeaderName })) : null,
        React.createElement(RequestGraph, { gridRows: allRows, onTimeRangeChanged: onGraphTimeRangeChanged })));
    function getKnownHeaderName(name) {
        switch (SubtraceEvent.knownFieldsFromJSON(name)) {
            case SubtraceEvent.KnownFields.time:
                return "Time";
            case SubtraceEvent.KnownFields.event_id:
                return "Event ID";
            case SubtraceEvent.KnownFields.service:
                return "Service";
            case SubtraceEvent.KnownFields.hostname:
                return "Host";
            case SubtraceEvent.KnownFields.process_id:
                return "PID";
            case SubtraceEvent.KnownFields.tls_server_name:
                return "Domain";
            case SubtraceEvent.KnownFields.http_version:
                return "HTTP Version";
            case SubtraceEvent.KnownFields.http_is_outgoing:
                return "Incoming / Outgoing";
            case SubtraceEvent.KnownFields.http_client_addr:
                return "Client IP";
            case SubtraceEvent.KnownFields.http_server_addr:
                return "Server IP";
            case SubtraceEvent.KnownFields.http_duration:
                return "Duration";
            case SubtraceEvent.KnownFields.http_req_method:
                return "Method";
            case SubtraceEvent.KnownFields.http_req_path:
                return "Path";
            case SubtraceEvent.KnownFields.http_resp_status_code:
                return "Status";
            case SubtraceEvent.KnownFields.UNRECOGNIZED:
            default:
                return name.toString();
        }
    }
    function getKnownCellDataType(type) {
        switch (type) {
            case "DateTime64(9)":
                return "dateString";
            case "String":
            case "Nullable(String)":
            case "LowCardinality(String)":
            case "UUID":
                return "text";
            case "UInt64":
            case "Nullable(UInt64)":
                return "number";
        }
    }
    function getKnownCellRenderer(name) {
        switch (SubtraceEvent.knownFieldsFromJSON(name)) {
            case SubtraceEvent.KnownFields.http_is_outgoing:
                return (params) => (React.createElement("span", { className: "h-full flex flex-row items-center" }, params.valueFormatted == "true" ? React.createElement(MoveUpRightIcon, { size: 20 }) : React.createElement(MoveDownLeftIcon, { size: 20 })));
        }
    }
    function getKnownValueFormatter(name) {
        switch (SubtraceEvent.knownFieldsFromJSON(name)) {
            case SubtraceEvent.KnownFields.time:
                return (params) => {
                    if (!params.data) {
                        return "";
                    }
                    // This cast is safe since we know that the timestamp is always represented as a string.
                    const timestampString = params.data[name];
                    return DateUtils.formatForDisplay(new Date(`${timestampString}${timestampString.endsWith("Z") ? "" : "Z"}`));
                };
            case SubtraceEvent.KnownFields.http_duration:
                return (params) => (params.data ? DurationUtils.formatForDisplay(BigInt(params.data[name])) : "");
            default:
                // This cast is safe since we assume unknown fields are represented as strings.
                return (params) => (params.data ? params.data[name] : "");
        }
    }
    function onGraphTimeRangeChanged(startTime, endTime) {
        const queryToDisplay = currentEvaluatingQuery ?? currentAppliedQuery;
        setCurrentEvaluatingQuery({
            clauses: queryToDisplay?.clauses ?? [],
            graphTimestampFilter: {
                kind: TimestampFilterKind.Between,
                lowerBound: startTime,
                upperBound: endTime,
            },
        });
    }
    function onClauseCommitted(clause) {
        const queryToDisplay = currentEvaluatingQuery ?? currentAppliedQuery;
        const clausesToDisplay = queryToDisplay?.clauses ?? [];
        const clauseIndex = clausesToDisplay.findIndex((_clause) => _clause.clauseId === clause.clauseId);
        if (clauseIndex !== -1) {
            // Editing an existing clause
            setCurrentEvaluatingQuery({
                clauses: [...clausesToDisplay.slice(0, clauseIndex), clause, ...clausesToDisplay.slice(clauseIndex + 1)],
                graphTimestampFilter: queryToDisplay?.graphTimestampFilter,
            });
        }
        else {
            // Adding a new clause
            setCurrentEvaluatingQuery({
                clauses: [...clausesToDisplay, clause],
                graphTimestampFilter: queryToDisplay?.graphTimestampFilter,
            });
        }
    }
    function onClauseClicked(clause) {
        clauseEditorRef.current?.setClauseBeingEdited(clause);
    }
    function renderClauses() {
        const queryToDisplay = currentEvaluatingQuery ?? currentAppliedQuery;
        const clausesToDisplay = queryToDisplay?.clauses ?? [];
        return (React.createElement("div", { className: "space-x-2 flex flex-row flex-wrap text-xs font-mono" }, clausesToDisplay.map((clause) => (React.createElement("div", { key: clause.clauseId, className: "py-1 px-2 my-1 text-white bg-zinc-700 rounded-md space-x-2 flex flex-row hover:bg-zinc-600 cursor-pointer", onClick: () => onClauseClicked(clause), title: clause.clauseText },
            React.createElement("span", { className: "select-none" }, clause.clauseText),
            React.createElement("span", { className: "hover:text-red-500", onClick: (event) => {
                    event.stopPropagation();
                    setCurrentEvaluatingQuery({
                        ...queryToDisplay,
                        clauses: clausesToDisplay.filter((_clause) => _clause.clauseId !== clause.clauseId),
                        graphTimestampFilter: queryToDisplay?.graphTimestampFilter,
                    });
                } },
                React.createElement(XCircleIcon, { className: "h-4 w-4" })))))));
    }
    function onRowDoubleClicked(event) {
        const nativeEvent = event.event;
        if (!(nativeEvent?.ctrlKey || nativeEvent?.metaKey) && event.data) {
            setSidePanelState({ isOpen: true, rowData: event.data });
        }
        else {
            // Not sure why this would happen, but log just in case
            console.log(event);
        }
    }
}
