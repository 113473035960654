import * as React from "react";
import { Checkbox } from "DesignComponents/Checkbox";
export function PinnedColumnCellRenderer(props) {
    return (React.createElement("span", { className: "h-full flex flex-row items-center" },
        React.createElement(Checkbox, { checked: props.value ?? false, classNames: { checkbox: "bg-zinc-900" }, labelElement: null, onCheckedChange: onCheckedChange })));
    function onCheckedChange(checked) {
        if (checked === "indeterminate") {
            throw new Error("Checkbox cannot be in an indeterminate state");
        }
        props.onChange(checked, props.data);
    }
}
