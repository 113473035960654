// JSON.stringify cannot serialize BigInts by default. To fix this, add custom
// serialization and deserialization logic.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
// for more details. Note that this will only work if we never see an object
// that has a key `$$bigint`.
export function stringify(value) {
    return JSON.stringify(value, (_key, value) => (typeof value === "bigint" ? { $$bigint: value.toString() } : value));
}
export function parse(text) {
    return JSON.parse(text, (_key, value) => value !== null && typeof value === "object" && "$$bigint" in value && typeof value.$$bigint === "string"
        ? BigInt(value.$$bigint)
        : value);
}
