import { TextFilterKind } from "TextFilter";
export function toSql(name, filter) {
    switch (filter.kind) {
        case TextFilterKind.Equals:
            return `${name} = ${filter.value}`;
        case TextFilterKind.DoesNotEqual:
            return `${name} != ${filter.value}`;
        case TextFilterKind.Like:
            return `${name} LIKE ${filter.value}`;
    }
}
