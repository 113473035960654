import * as ResultUtils from "Utils/ResultUtils";
export function parse(value) {
    try {
        return ResultUtils.success(BigInt(value));
    }
    catch (error) {
        if (error instanceof Error) {
            return ResultUtils.failure(error.message);
        }
        // We don't know what would cause this, just throw.
        throw error;
    }
}
