import React from "react";
import { CircleAlertIcon, XIcon } from "lucide-react";
import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";
export function DeleteConfirmationDialog(props) {
    return (React.createElement("div", { className: "relative h-1/4 min-w-[25%] bg-zinc-900 p-10 text-sm rounded-lg shadow-lg" },
        React.createElement(XIcon, { className: StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white"), onClick: () => props.closeModal(/* wasCommitted */ false) }),
        React.createElement("div", { className: "flex flex-col justify-between h-full" },
            React.createElement("div", { className: "text-white" },
                React.createElement("span", { className: "flex flex-row mt-3 space-x-1 items-center" },
                    React.createElement(CircleAlertIcon, { size: 20, className: "text-red-600 mr-2" }),
                    React.createElement("span", null, props.message))),
            React.createElement("div", { className: "flex flex-row justify-end space-x-4" },
                React.createElement(Button, { className: StyleUtils.mergeClassNames("text-red-600 bg-zinc-700 h-8 px-4 outline-0 rounded", "hover:bg-red-600 hover:text-white"), label: "Delete", onClick: () => props.closeModal(/* wasCommitted */ true) }),
                React.createElement(Button, { className: StyleUtils.mergeClassNames("text-white bg-zinc-700/80 h-8 px-4 outline-0 rounded", "hover:bg-zinc-700"), label: "Cancel", onClick: () => props.closeModal(/* wasCommitted */ false) })))));
}
