import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
// Gets the default namespace that we show the user when they log in.
// This corresponds to the first namespace of the first org that they have access to.
export async function getDefaultNamespace() {
    const listOrgsRequest = {};
    const listOrgsResponse = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, /* options */ {});
    await ApiUtils.assertStatus(listOrgsResponse, 200);
    const { orgs } = await listOrgsResponse.json();
    const listNamespacesRequest = { orgId: orgs[0].orgId };
    const listNamespacesResponse = await ApiUtils.post("/api/ListNamespaces", listNamespacesRequest, 
    /* options */ {});
    await ApiUtils.assertStatus(listNamespacesResponse, 200);
    const { namespaces } = await listNamespacesResponse.json();
    ArrayUtils.assertLengthAtLeast(namespaces, 1);
    return namespaces[0];
}
