import React from "react";
import { ClipboardCopyIcon, XIcon } from "lucide-react";
import * as StyleUtils from "Utils/StyleUtils";
export function RequestDetailsSidePanel(props) {
    const [copyButtonTooltipText, setCopyButtonTooltipText] = React.useState("Copy");
    return (React.createElement("div", { className: "absolute right-0 top-0 h-full w-max-[30%]p-10 animate-slide-in-right z-10 rounded-lg bg-zinc-900 border-[1px] border-zinc-700/80 outline outline-[1px] outline-black" },
        React.createElement(XIcon, { className: StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white"), onClick: () => props.closeSidePanel() }),
        React.createElement("div", { className: "flex flex-col space-y-6 p-4 text-white" },
            React.createElement("div", { className: "font-medium text-lg" }, "Request details"),
            React.createElement("span", { className: "h-[1px] bg-zinc-800" }),
            React.createElement("div", { className: "flex flex-col space-y-4" }, Object.entries(props.rowData).map(([name, value]) => renderRow(name, value))))));
    function renderRow(name, value) {
        const displayedValue = value.toString();
        return (React.createElement("span", { key: name, className: "group/item flex flex-row content-center flex-wrap text-sm", onMouseLeave: () => setCopyButtonTooltipText("Copy") },
            React.createElement("span", { className: "grow" },
                React.createElement("span", null,
                    props.getKnownHeaderName(name),
                    ": "),
                React.createElement("span", { className: "font-mono" }, displayedValue)),
            React.createElement("span", { className: "ml-8 mr-6 group/copy-button relative invisible group-hover/item:visible" },
                React.createElement(ClipboardCopyIcon, { className: "h-5 w-5 text-zinc-300 hover:text-zinc-100 cursor-pointer", onClick: () => {
                        navigator.clipboard.writeText(displayedValue);
                        setCopyButtonTooltipText("Copied value");
                    } }),
                React.createElement("span", { className: "invisible group-hover/copy-button:visible absolute bottom-7 text-xs border rounded-sm border-white left-1/2 -translate-x-1/2 p-1 bg-zinc-700 text-nowrap" }, copyButtonTooltipText))));
    }
}
