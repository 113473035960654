import * as Verify from "Utils/Verify";
import { Granularity } from "Granularity";
export function getIntervalDuration(granularity) {
    switch (granularity) {
        case Granularity.SevenDays:
            return 7 * 24 * 60 * 60 * 1000;
        case Granularity.Day:
            return 24 * 60 * 60 * 1000;
        case Granularity.SixHours:
            return 6 * 60 * 60 * 1000;
        case Granularity.TwoHours:
            return 2 * 60 * 60 * 1000;
        case Granularity.Hour:
            return 60 * 60 * 1000;
        case Granularity.ThirtyMintues:
            return 30 * 60 * 1000;
        case Granularity.TenMinutes:
            return 10 * 60 * 1000;
        case Granularity.Minute:
            return 60 * 1000;
        case Granularity.TenSeconds:
            return 10 * 1000;
        case Granularity.FiveSeconds:
            return 5 * 1000;
        case Granularity.Second:
            return 1000;
        default:
            Verify.isNever(granularity);
    }
}
export function getBestFitFromIntervalDuration(intervalDuration) {
    if (intervalDuration <= 1000) {
        return Granularity.Second;
    }
    else if (intervalDuration <= 5 * 1000) {
        return Granularity.FiveSeconds;
    }
    else if (intervalDuration <= 10 * 1000) {
        return Granularity.TenSeconds;
    }
    else if (intervalDuration <= 60 * 1000) {
        return Granularity.Minute;
    }
    else if (intervalDuration <= 10 * 60 * 1000) {
        return Granularity.TenMinutes;
    }
    else if (intervalDuration <= 30 * 60 * 1000) {
        return Granularity.ThirtyMintues;
    }
    else if (intervalDuration <= 60 * 60 * 1000) {
        return Granularity.Hour;
    }
    else if (intervalDuration <= 2 * 60 * 60 * 1000) {
        return Granularity.TwoHours;
    }
    else if (intervalDuration <= 6 * 60 * 60 * 1000) {
        return Granularity.SixHours;
    }
    else if (intervalDuration <= 24 * 60 * 60 * 1000) {
        return Granularity.Day;
    }
    else {
        return Granularity.SevenDays;
    }
}
