import * as React from "react";
import * as StyleUtils from "Utils/StyleUtils";
export function TextField(props) {
    return (React.createElement("div", { className: StyleUtils.mergeClassNames("flex flex-col", props.classNames?.root) },
        props.label ? React.createElement("span", { className: "text-white text-sm" }, props.label) : null,
        React.createElement("input", { autoFocus: props.autoFocus, className: StyleUtils.mergeClassNames("rounded-md bg-slate-200 cursor-pointer hover:brightness-[1.05] px-4 py-2 text-sm outline outline-2 outline-zinc-600/60 active:outline-zinc-600/80 focus:outline-zinc-600/70", props.classNames?.input), disabled: props.disabled, onChange: onValueChange, onKeyDown: onKeyDown, placeholder: props.placeholder, type: props.type, value: props.value }),
        props.errorMessage ? React.createElement("span", { className: "text-red-600 text-xs" }, props.errorMessage) : null));
    function onKeyDown(event) {
        props.onKeyDown?.(event);
    }
    function onValueChange(event) {
        props.onChange(event.target.value);
    }
}
