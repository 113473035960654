export function assertLengthAtLeast(array, minLength) {
    if (array.length < minLength) {
        throw new Error(`Expected array length to be at least ${minLength}, got an array of length ${array.length} instead`);
    }
}
export function extendByRepetition(array, finalLength) {
    const result = [];
    for (let i = 0; i < finalLength; i++) {
        result.push(array[i % array.length]);
    }
    return result;
}
