import * as React from "react";
import * as ReactDomClient from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as CustomHooks from "CustomHooks";
import * as NamespaceUtils from "NamespaceUtils";
import { AppContents } from "AppContents";
import { AppMain } from "AppMain";
import { Deferred } from "Deferred";
import { DialogContext } from "DialogContext";
import { LandingPage } from "LandingPage";
import { LoginPage } from "./LoginPage";
import { NamespacesPage } from "NamespacesPage";
import { Overlay } from "Overlay";
import { SettingsPage } from "SettingsPage";
import { TokensPage } from "TokensPage";
import { UsersPage } from "UsersPage";
import "./style.css";
const rootElement = document.getElementById("root");
ReactDomClient.createRoot(rootElement).render(React.createElement(App, null));
function App() {
    const [dialogState, setDialogState] = React.useState({ isDialogOpen: false });
    let deferred;
    const dialogManager = {
        show: (dialogComponent) => {
            deferred = new Deferred();
            setDialogState({
                isDialogOpen: true,
                dialogComponent,
            });
            return deferred.promise;
        },
        hide: (result) => {
            setDialogState({ isDialogOpen: false });
            deferred.resolve(result);
        },
    };
    const currentUser = CustomHooks.useCurrentUser();
    const [namespace, setNamespace] = React.useState(undefined);
    // TODO(sachin): Move to persistent-ish storage, like browser storage
    const [settings, setSettings] = React.useState({
        highlightNon200Rows: false,
    });
    React.useEffect(() => {
        if (currentUser?.isLoggedIn) {
            NamespaceUtils.getDefaultNamespace().then((_namespace) => setNamespace(_namespace));
        }
    }, [currentUser]);
    if (currentUser === undefined) {
        return null;
    }
    return (React.createElement(React.StrictMode, null,
        React.createElement(DialogContext.Provider, { value: dialogManager },
            React.createElement(BrowserRouter, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/", element: React.createElement(LandingPage, null) }),
                    React.createElement(Route, { path: "/dashboard", element: React.createElement(Navigate, { to: "/dashboard/requests" }) }),
                    React.createElement(Route, { path: "/dashboard/*", element: currentUser.isLoggedIn ? React.createElement(AppMain, { namespace: namespace, onNamespaceChanged: setNamespace }) : React.createElement(Navigate, { to: "/login" }) },
                        React.createElement(Route, { path: "namespaces", element: React.createElement(NamespacesPage, { namespace: namespace, onNamespaceChanged: setNamespace }) }),
                        React.createElement(Route, { path: "requests", element: React.createElement(AppContents, { namespaceId: namespace?.namespaceId }) }),
                        React.createElement(Route, { path: "settings", element: React.createElement(SettingsPage, { settings: settings, onSettingsChanged: setSettings }) }),
                        React.createElement(Route, { path: "tokens", element: React.createElement(TokensPage, { namespaceId: namespace?.namespaceId }) }),
                        React.createElement(Route, { path: "users", element: React.createElement(UsersPage, { namespaceId: namespace?.namespaceId }) })),
                    React.createElement(Route, { path: "/login", element: React.createElement(LoginPage, null) })),
                dialogState.isDialogOpen ? React.createElement(Overlay, { className: "flex items-center justify-center" }, dialogState.dialogComponent) : null))));
}
