import { ResultKind } from "./Result";
// Constructors
export function success(value) {
    return {
        kind: ResultKind.Success,
        value,
    };
}
export function failure(error) {
    return {
        kind: ResultKind.Failure,
        error,
    };
}
// Type guards
export function isSuccess(result) {
    return result.kind === ResultKind.Success;
}
export function isFailure(result) {
    return result.kind === ResultKind.Failure;
}
