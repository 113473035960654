import * as React from "react";
import { User, Loader2 } from "lucide-react";
import * as ApiUtils from "ApiUtils";
function Row(props) {
    const joinTime = new Date(props.user.joinTime);
    return (React.createElement("div", { className: "w-full py-2 items-center grid grid-cols-12 hover:bg-zinc-900/25 group" },
        React.createElement("div", { className: "px-2 col-span-8 text-zinc-300 text-xs truncated text-ellipsis flex items-center space-x-2" },
            React.createElement("span", { className: "w-[14px] h-[14px] flex items-center" },
                React.createElement(User, null)),
            React.createElement("span", null, props.user.email)),
        React.createElement("div", { title: joinTime.toISOString(), className: "px-2 col-span-3 text-zinc-400 text-xs" }, joinTime.toDateString())));
}
function List(props) {
    return (React.createElement("div", { className: "w-full z-0" },
        React.createElement("div", { className: "flex mb-4" },
            React.createElement("span", { className: "px-3 py-1 bg-zinc-800/80 rounded-full text-zinc-500 text-[10px] font-semibold" },
                props.users.length,
                " ",
                props.users.length == 1 ? "user" : "users")),
        React.createElement("div", { className: "w-full flex flex-col divide-y divide-zinc-900" },
            React.createElement("div", { className: "rounded w-full py-2 items-center grid grid-cols-12" },
                React.createElement("div", { className: "px-2 col-span-8 text-zinc-500/80 text-[10px] uppercase font-semibold" }, "Email"),
                React.createElement("div", { className: "px-2 col-span-3 text-zinc-500/80 text-[10px] uppercase font-semibold" }, "Joined")),
            props.users.map((user) => (React.createElement(Row, { key: user.userId, user: user }))))));
}
export function UsersPage(props) {
    const [users, setUsers] = React.useState(undefined);
    const { namespaceId: namespaceId } = props;
    React.useEffect(() => {
        const controller = new AbortController();
        async function refreshUsers(signal) {
            if (!namespaceId) {
                return;
            }
            const request = { namespaceId };
            try {
                const response = await ApiUtils.post("/api/ListUsers", request, { signal });
                await ApiUtils.assertStatus(response, 200);
                const { users } = await response.json();
                setUsers(users);
            }
            catch {
                if (signal?.aborted) {
                    // Do nothing, the API call was canceled
                }
            }
        }
        refreshUsers(controller.signal);
        return () => {
            controller.abort("Cleaning up effect refreshUsers");
        };
    }, [namespaceId]);
    if (!namespaceId) {
        return null;
    }
    if (users == undefined) {
        return (React.createElement("div", { className: "pt-4 w-full flex items-center" },
            React.createElement("div", { className: "w-full h-full flex justify-center items-center rounded-md text-zinc-600" },
                React.createElement("div", { className: "w-4 h-4 flex justify-center items-center" },
                    React.createElement("span", { className: "w-[14px] h-[14px] flex items-center animate-spin-loader" },
                        React.createElement(Loader2, null))))));
    }
    return (React.createElement("div", { className: "w-full flex justify-center" },
        React.createElement("div", { className: "w-full max-w-xl px-8 py-12" },
            React.createElement("div", { className: "flex flex-col space-y-8" },
                React.createElement("span", { className: "text-zinc-200 text-sm" }, "Namespace admins"),
                React.createElement(List, { users: users.filter((user) => user.hasWrite) }),
                React.createElement("span", { className: "text-zinc-200 text-sm" }, "Namespace members"),
                React.createElement(List, { users: users.filter((user) => !user.hasWrite) })))));
}
