import * as React from "react";
import * as ApiUtils from "ApiUtils";
import * as ResultUtils from "Utils/ResultUtils";
import * as StrictUtils from "Utils/StrictUtils";
import * as Tunnel from "ApiContracts/subtrace/tunnel/tunnel";
import { DialogContext } from "DialogContext";
export function useCurrentUser() {
    const [currentUser, setCurrentUser] = React.useState(undefined);
    React.useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        async function tryLogIn() {
            try {
                const response = await ApiUtils.get("/api/GetSelf", { signal });
                if (response.status !== 200) {
                    setCurrentUser({ isLoggedIn: false });
                    return;
                }
                const { email, userId } = await response.json();
                setCurrentUser({ isLoggedIn: true, email, userId });
            }
            catch (error) {
                if (controller.signal.aborted) {
                    // Do nothing since the API call was aborted, likely because the user navigated back in their
                    // browser or reloaded the page.
                }
                else {
                    setCurrentUser({ isLoggedIn: false });
                    // TODO: Setup logging/telemetry so we can look at cases like this, instead of logging to console.
                    // https://github.com/subtrace/monorepo/issues/21
                    console.log(`Failed to get current user: Error ${error}`);
                }
            }
        }
        tryLogIn();
        return () => {
            controller.abort("Cleaning up effect: useCurrentUser");
        };
    }, []);
    return currentUser;
}
export function useDialogManager() {
    return StrictUtils.ensureDefined(React.useContext(DialogContext));
}
/**
 * Returns a stateful variable that is:
 * - An initialized websocket that is ready to send queries to, if initialization succeeds
 * - An error string if the initialization fails
 * - `undefined` while the initialization is happening.
 */
export function useInitializedWebSocket(namespaceId) {
    const [result, setResult] = React.useState(undefined);
    React.useEffect(() => {
        if (!namespaceId) {
            return;
        }
        const controller = new AbortController();
        const { signal } = controller;
        let webSocket;
        async function tryInitializeWebSocket() {
            const createTunnelRequest = {
                namespaceId,
                role: Tunnel.Role.SELECT,
            };
            let endpoint;
            try {
                const response = await ApiUtils.post("/api/CreateTunnel", createTunnelRequest, { signal });
                await ApiUtils.assertStatus(response, 200);
                const data = await response.json();
                endpoint = data.endpoint;
            }
            catch (error) {
                if (controller.signal.aborted) {
                    // Do nothing since the API call was aborted, likely because the user navigated back in their
                    // browser or reloaded the page.
                    return undefined;
                }
                else {
                    // TODO: Setup logging/telemetry so we can look at cases like this, instead of logging to console.
                    // https://github.com/subtrace/monorepo/issues/21
                    console.log(`Failed to create tunnel: ${error}`);
                    setResult(ResultUtils.failure(error instanceof Error ? error.message : JSON.stringify(error)));
                    return;
                }
            }
            webSocket = new WebSocket(endpoint);
            webSocket.binaryType = "arraybuffer";
            webSocket.onopen = () => {
                setResult(ResultUtils.success(webSocket));
            };
        }
        tryInitializeWebSocket();
        return () => {
            controller.abort("Cleaning up effect: useInitializedWebSocket");
            webSocket?.close();
        };
    }, [namespaceId]);
    return result;
}
