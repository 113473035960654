import { NumericalFilterKind } from "NumericalFilter";
import * as Verify from "Utils/Verify";
export function getFilterStringValues(filter) {
    if (!filter) {
        return "";
    }
    switch (filter.kind) {
        case NumericalFilterKind.Equals:
        case NumericalFilterKind.GreaterThan:
        case NumericalFilterKind.GreaterThanOrEquals:
        case NumericalFilterKind.LesserThan:
        case NumericalFilterKind.LesserThanOrEquals:
        case NumericalFilterKind.NotEquals:
            return filter.value.toString();
        default:
            Verify.isNever(filter.kind);
    }
}
export function toSql(name, filter) {
    switch (filter.kind) {
        case NumericalFilterKind.Equals:
            return `${name} = ${filter.value.toString()}`;
        case NumericalFilterKind.GreaterThan:
            return `${name} > ${filter.value.toString()}`;
        case NumericalFilterKind.GreaterThanOrEquals:
            return `${name} >= ${filter.value.toString()}`;
        case NumericalFilterKind.LesserThan:
            return `${name} < ${filter.value.toString()}`;
        case NumericalFilterKind.LesserThanOrEquals:
            return `${name} <= ${filter.value.toString()}`;
        case NumericalFilterKind.NotEquals:
            return `${name} != ${filter.value.toString()}`;
    }
}
