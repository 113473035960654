import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowDownUp, Grip, Key, Users, BookOpen, MessagesSquare, LogOut, Loader2, Settings } from "lucide-react";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as StrictUtils from "Utils/StrictUtils";
import { Select } from "DesignComponents/Select";
function NamespaceSelector(props) {
    const [namespaces, setNamespaces] = React.useState(undefined);
    React.useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        async function getNamespaces() {
            try {
                const listOrgsRequest = {};
                const listOrgsResponse = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, { signal });
                await ApiUtils.assertStatus(listOrgsResponse, 200);
                const { orgs } = await listOrgsResponse.json();
                ArrayUtils.assertLengthAtLeast(orgs, 1);
                // We currently assume that there's only one org and we're always dealing with the first one
                const request = { orgId: orgs[0].orgId };
                const response = await ApiUtils.post("/api/ListNamespaces", request, { signal });
                await ApiUtils.assertStatus(response, 200);
                const { namespaces } = await response.json();
                setNamespaces(namespaces);
            }
            catch {
                if (controller.signal.aborted) {
                    // Do nothing, the API call was canceled
                }
            }
        }
        getNamespaces();
        return () => {
            controller.abort("Cleaning up effect getNamespaces");
        };
    }, []);
    if (!props.namespace) {
        return null;
    }
    if (namespaces === undefined) {
        return (React.createElement("div", { className: "flex justify-between rounded-md text-zinc-600" },
            React.createElement("div", { className: "flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs" },
                React.createElement("div", { className: "w-4 h-4 flex justify-center items-center" },
                    React.createElement("span", { className: "w-[14px] h-[14px] flex items-center animate-spin-loader" },
                        React.createElement(Loader2, null))))));
    }
    return (React.createElement("div", { className: "flex flex-col items-center space-y-1 py-2 rounded-md font-medium text-xs text-zinc-300" },
        React.createElement("span", { className: "self-start" }, "Namespace"),
        namespaces.length > 0 ? (React.createElement(Select, { ariaLabel: "Select namespace", className: "h-8 px-2 font-medium text-xs self-stretch", items: namespaces.map((namespace) => ({
                displayElement: namespace.name,
                itemKey: namespace.namespaceId,
                value: namespace,
            })), onChange: props.onNamespaceChanged, value: StrictUtils.ensureDefined(namespaces.find((namespace) => namespace.namespaceId === props.namespace?.namespaceId)) })) : (React.createElement("span", { className: "emphasis text-zinc-500" }, "No namespace"))));
}
const Navlink = (props) => {
    const location = ReactRouterDom.useLocation();
    return (React.createElement(Link, { to: props.path, className: [
            "flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100",
            location.pathname === props.path ? "bg-zinc-700/80 hover:bg-zinc-700" : "bg-transparent hover:bg-zinc-800",
        ].join(" ") },
        React.createElement("div", { className: "w-4 h-4 flex justify-center items-center" },
            React.createElement("span", { className: "w-[14px] h-[14px] flex items-center" }, props.icon)),
        React.createElement("span", null, props.text)));
};
function PageSelector() {
    return (React.createElement("div", { className: "flex flex-col space-y-1" },
        React.createElement(Navlink, { path: "/dashboard/requests", icon: React.createElement(ArrowDownUp, null), text: "Requests" }),
        React.createElement(Navlink, { path: "/dashboard/tokens", icon: React.createElement(Key, null), text: "Tokens" }),
        React.createElement(Navlink, { path: "/dashboard/users", icon: React.createElement(Users, null), text: "Users" }),
        React.createElement(Navlink, { path: "/dashboard/settings", icon: React.createElement(Settings, null), text: "Settings" }),
        React.createElement(Navlink, { path: "/dashboard/namespaces", icon: React.createElement(Grip, null), text: "Namespaces" })));
}
function HelpLinks() {
    const Discord = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 127.14, height: 96.36, viewBox: "0 0 127.14 96.36" },
        React.createElement("path", { fill: "#d4d4d8", d: "M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" })));
    return (React.createElement("div", { className: "flex flex-col space-y-1" },
        React.createElement(Navlink, { path: "https://subtrace.dev/discord", icon: React.createElement(Discord, null), text: "Discord" }),
        React.createElement(Navlink, { path: "https://docs.subtrace.dev", icon: React.createElement(BookOpen, null), text: "Docs" }),
        React.createElement(Navlink, { path: "mailto:support@subtrace.dev", icon: React.createElement(MessagesSquare, null), text: "Support" })));
}
function UserSection() {
    return (React.createElement("div", { className: "w-full px-4 py-2 flex justify-between" },
        React.createElement("button", { onClick: () => (document.location = "/api/LogOut"), className: "-m-2 w-8 h-8 rounded-full text-zinc-500 hover:text-zinc-300 cursor-pointer flex justify-center items-center group relative" },
            React.createElement("div", { className: "w-[14px] h-[14px] flex items-center justif-center" },
                React.createElement(LogOut, null)),
            React.createElement("div", { className: "whitespace-pre hidden group-hover:flex absolute -top-3 left-[50%] translate-x-[-50%] text-center text-[10px] justify-center items-center" },
                React.createElement("span", null, "Logout")))));
}
export function Navigation(props) {
    return (React.createElement("div", { className: "basis-1/3 grow min-w-48 max-w-60" },
        React.createElement("div", { className: "w-full h-full flex flex-col justify-between py-4 px-3 bg-zinc-900/50" },
            React.createElement("div", { className: "flex flex-col space-y-4" },
                React.createElement(NamespaceSelector, { namespace: props.namespace, onNamespaceChanged: props.onNamespaceChanged }),
                React.createElement("span", { className: "h-[1px] bg-zinc-800" }),
                React.createElement(PageSelector, null)),
            React.createElement("div", { className: "flex flex-col space-y-4" },
                React.createElement(HelpLinks, null),
                React.createElement(UserSection, null)))));
}
