import * as RadixUICheckbox from "@radix-ui/react-checkbox";
import React from "react";
import { CheckIcon, MinusIcon } from "lucide-react";
import * as StyleUtils from "Utils/StyleUtils";
export function Checkbox(props) {
    const idForCheckbox = crypto.randomUUID();
    return (React.createElement("div", { className: StyleUtils.mergeClassNames("group flex items-center", props.classNames?.root) },
        React.createElement(RadixUICheckbox.Root, { className: StyleUtils.mergeClassNames("border flex h-4 w-4 appearance-none items-center justify-center rounded-sm", props.classNames?.checkbox), checked: props.checked, onCheckedChange: props.onCheckedChange, id: idForCheckbox },
            React.createElement(RadixUICheckbox.Indicator, null,
                props.checked === "indeterminate" && React.createElement(MinusIcon, { className: "h-3 w-3" }),
                props.checked === true && React.createElement(CheckIcon, { className: "p-[2px] h-3 w-3", strokeWidth: 6 }))),
        React.createElement("label", { className: StyleUtils.mergeClassNames("pl-2 text-[15px] leading-none", props.classNames?.label), htmlFor: idForCheckbox }, props.labelElement)));
}
