import * as React from "react";
import { Loader2, Trash } from "lucide-react";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { GetApiTokenDialog } from "GetApiTokenDialog";
import { InsufficientPermissionsDialog } from "InsufficientPermissionsDialog";
import { DeleteConfirmationDialog } from "DeleteConfirmationDialog";
function List(props) {
    const dialogManager = CustomHooks.useDialogManager();
    const { namespaceId } = props;
    if (!namespaceId) {
        return null;
    }
    if (props.tokens === null) {
        return (React.createElement("div", { className: "pt-4 w-full flex items-center" },
            React.createElement("div", { className: "w-full h-full flex justify-center items-center rounded-md text-zinc-600" },
                React.createElement("div", { className: "w-4 h-4 flex justify-center items-center" },
                    React.createElement("span", { className: "w-[14px] h-[14px] flex items-center animate-spin-loader" },
                        React.createElement(Loader2, null))))));
    }
    if (props.tokens.length == 0) {
        return (React.createElement("div", { className: "pt-4 w-full flex items-center" },
            React.createElement("div", { className: "w-full h-full flex justify-center items-center border border-zinc-800/80 rounded-md text-zinc-600 py-8" },
                React.createElement("div", { className: "flex justify-center items-center" },
                    React.createElement("div", { className: "text-xs flex flex-col space-y-2" },
                        React.createElement("span", null, "This namespace has no tokens."),
                        React.createElement("button", { className: "mx-auto rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1", onClick: () => props.generateToken(namespaceId) },
                            React.createElement("span", null, "New token")))))));
    }
    const deleteToken = async (tokenId, namespaceId) => {
        const wasCommitted = await dialogManager.show(React.createElement(DeleteConfirmationDialog, { message: "Are you sure you want to delete this token? This action cannot be undone.", closeModal: (wasCommitted) => dialogManager.hide(wasCommitted) }));
        if (!wasCommitted) {
            return;
        }
        const response = await ApiUtils.post("/api/DeleteToken", { tokenId }, {});
        if (response.status === 403) {
            return dialogManager.show(React.createElement(InsufficientPermissionsDialog, { message: "You do not have permissions to delete a token in this namespace.", closeModal: () => dialogManager.hide(undefined) }));
        }
        await ApiUtils.assertStatus(response, 200);
        await props.refreshTokens(namespaceId);
    };
    return (React.createElement("div", { className: "rounded overflow-hidden border border-zinc-800/80 w-full flex flex-col divide-y divide-zinc-800/80" }, props.tokens.map(({ tokenId, createTime, name, creatorEmail }) => {
        const diff = (Date.now() - new Date(createTime).getTime()) / 1000;
        let count, unit;
        if (diff < 60)
            [count, unit] = [Math.round(diff), "second"];
        else if (diff < 60 * 60)
            [count, unit] = [Math.round(diff / 60), "minute"];
        else if (diff < 24 * 60 * 60)
            [count, unit] = [Math.round(diff / 60 / 60), "hour"];
        else
            [count, unit] = [Math.round(diff / 60 / 60 / 24), "day"];
        if (count != 1)
            unit += "s";
        return (React.createElement("div", { className: "w-full px-4 py-2 flex justify-between items-center hover:bg-zinc-900 group relative", key: tokenId },
            React.createElement("div", { className: "font-mono text-zinc-300 text-sm truncated text-ellipsis" }, name),
            React.createElement("div", { className: "flex flex-col space-y-[1px] items-end group-hover:opacity-0" },
                React.createElement("div", { className: "font-medium text-zinc-600 text-[10px]" }, creatorEmail),
                React.createElement("div", { title: createTime, className: "text-zinc-600/80 text-[10px]" },
                    count,
                    " ",
                    unit,
                    " ago")),
            React.createElement("button", { onClick: () => deleteToken(tokenId, namespaceId), title: "Delete", className: "hidden absolute top-0 right-0 w-16 cursor-pointer group-hover:flex justify-center items-center h-full bg-gradient-to-l from-red-900/20 hover:from-red-900/30 to-transparent text-red-600 hover:text-red-400" },
                React.createElement("span", { className: "w-[14px] h-[14px] flex items-center" },
                    React.createElement(Trash, null)))));
    })));
}
export function TokensPage(props) {
    const [tokens, setTokens] = React.useState(null);
    const dialogManager = CustomHooks.useDialogManager();
    const { namespaceId: namespaceId } = props;
    React.useEffect(() => {
        const controller = new AbortController();
        if (namespaceId) {
            refreshTokens(namespaceId, controller.signal);
        }
        return () => {
            controller.abort("Cleaning up effect refreshTokens");
        };
    }, [namespaceId]);
    if (!namespaceId) {
        return null;
    }
    return (React.createElement("div", { className: "w-full flex justify-center" },
        React.createElement("div", { className: "w-full max-w-xl px-8 py-12" },
            React.createElement("div", { className: "flex flex-col space-y-8" },
                React.createElement("div", { className: "flex flex-col space-y-4" },
                    React.createElement("div", { className: "flex items-center justify-between space-x-2" },
                        React.createElement("div", { className: "text-xs text-zinc-500 max-w-80" },
                            "Subtrace API tokens are used by clients to authenticate when pushing or querying tracing data.",
                            " ",
                            React.createElement("a", { className: "brightness-[1.3] hover:brightness-[1.5]", href: "https://docs.subtrace.dev" }, "Learn more.")),
                        React.createElement("button", { className: "rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1", onClick: () => generateToken(namespaceId) },
                            React.createElement("span", null, "Create API token"),
                            React.createElement("span", null, "\u2192")))),
                React.createElement(List, { namespaceId: namespaceId, tokens: tokens, refreshTokens: refreshTokens, generateToken: generateToken })))));
    async function generateToken(namespaceId) {
        const request = { namespaceId };
        const response = await ApiUtils.post("/api/GenerateToken", request, {});
        if (response.status === 403) {
            return dialogManager.show(React.createElement(InsufficientPermissionsDialog, { message: "You do not have permissions to generate a token in this namespace.", closeModal: () => dialogManager.hide(undefined) }));
        }
        await ApiUtils.assertStatus(response, 200);
        const { token } = await response.json();
        await dialogManager.show(React.createElement(GetApiTokenDialog, { apiToken: token, closeModal: () => dialogManager.hide(undefined) }));
        await refreshTokens(namespaceId);
    }
    async function refreshTokens(namespaceId, signal) {
        const request = { namespaceId };
        try {
            const response = await ApiUtils.post("/api/ListTokens", request, { signal });
            await ApiUtils.assertStatus(response, 200);
            const { tokens } = await response.json();
            setTokens(tokens);
        }
        catch {
            if (signal?.aborted) {
                // Do nothing, the API call was canceled
            }
        }
    }
}
