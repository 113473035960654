import React from "react";
import { XIcon } from "lucide-react";
import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";
import { TextField } from "DesignComponents/TextField";
export function NamespaceCreationDialog(props) {
    const [name, setName] = React.useState("");
    return (React.createElement("div", { className: "relative min-w-[25%] bg-zinc-900 p-10 text-sm rounded-lg shadow-lg" },
        React.createElement(XIcon, { className: StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white"), onClick: () => props.closeModal(undefined) }),
        React.createElement("div", { className: "flex flex-col space-y-6 justify-between h-full" },
            React.createElement("span", { className: "flex flex-row mt-3 space-x-1 items-center" },
                React.createElement("span", { className: "text-white" }, "Provide a name for the new namespace:")),
            React.createElement("div", { className: "flex flex-row space-x-4" },
                React.createElement("div", { className: "grow" },
                    React.createElement(TextField, { onKeyDown: onTextFieldKeyDown, autoFocus: true, classNames: { input: "h-8 px-2" }, value: name, onChange: setName })),
                React.createElement(Button, { className: "h-8 px-4 outline-0 rounded", label: "Create", onClick: () => props.closeModal(name) })))));
    function onTextFieldKeyDown(event) {
        if (event.key === "Enter") {
            props.closeModal(name);
        }
    }
}
